import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPoolId = "us-east-1_nhYQZ6Jva";
const appClientId = "4ffdqcgi7f9tleqkpj80dc5irr";

const poolData = {
  UserPoolId: userPoolId,
  ClientId: appClientId,
};

const userPool = new CognitoUserPool(poolData);

const logout = () => {
  localStorage.removeItem("accessToken");
};

const isAuthenticated = () => {
  return localStorage.getItem("accessToken") !== null;
};

const checkSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.error("Error with session:", err);
          logout();
          reject(err); // reject the promise if there is an error
        } else if (!session.isValid()) {
          console.log("Session is invalid, trying to refresh it...");
          // getSession should try to refresh the session if the refresh token is still valid
          // If you can refresh the session, resolve the promise, otherwise reject it
        } else {
          updateStoredTokens(session);
          console.log("Session is valid now and tokens have been refreshed");
          resolve(session); // resolve the promise when session is valid
        }
      });
    } else {
      // Handle the scenario where there is no user
      reject("No user is currently authenticated.");
    }
  });
};

const updateStoredTokens = (session) => {
  // Store or update tokens in local storage
  const idToken = session.getIdToken().getJwtToken();
  localStorage.setItem("accessToken", idToken);
  // You may not need to update the refresh token if it hasn't expired.
};

export { logout, checkSession, updateStoredTokens, isAuthenticated };
