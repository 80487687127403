import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import ChatIcon from "@mui/icons-material/Chat";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useProfileImage } from "./ProfileImageProvider";
import { useProjects } from "./ProjectsProvider";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import { checkSession } from "./auth";
import Grid from "@mui/material/Grid";

const cdn = "https://d71lxjj3xpfs2.cloudfront.net/";

const userPoolId = "us-east-1_nhYQZ6Jva";
const appClientId = "4ffdqcgi7f9tleqkpj80dc5irr";

const poolData = {
  UserPoolId: userPoolId,
  ClientId: appClientId,
};

const userPool = new CognitoUserPool(poolData);

const defaultFilterOptions = createFilterOptions();

const TopAppBar = ({ wsRef }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const isChatPage = location.pathname === "/chat"; // Check if it's the login page
  const [anchorNotifs, setAnchorNotifs] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const open = Boolean(anchorNotifs);
  const handleClick = (event) => {
    setAnchorNotifs(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorNotifs(null);
  };
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const { profileImageUrl, updateProfileImageUrl } = useProfileImage();
  const { projects } = useProjects();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    // Check if the WebSocket instance exists
    if (wsRef.current) {
      // Define the message handler
      const handleMessage = (event) => {
        if (!isChatPage) {
          console.log("Message received:", event.data);
          setUnreadCount((prevCount) => prevCount + 1);
        }
        // const messageObject = JSON.parse(event.data);
      };

      // Assign the message handler to the WebSocket instance
      wsRef.current.onmessage = handleMessage;

      // Cleanup function to remove the message handler
      return () => {
        if (wsRef.current) {
          wsRef.current.onmessage = null;
        }
      };
    }
  }, [wsRef.current, location.pathname]); // Depend on ws.current so that if the WebSocket instance changes, useEffect will run again

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      const getNotifications = () => {
        fetch(
          `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/notifications?id=${localStorage.getItem(
            "userId"
          )}`,
          {
            headers: {
              Authorization: `${localStorage.getItem("accessToken")}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((result) => {
            if (result.notifs.length > 0) {
              setNotificationsCount(result.notifs.length);
              setNotifications(result.notifs);
            }
            setUnreadCount(result.unreadCount);
          });
      };

      async function init() {
        await checkSession();
        getNotifications();
      }
      init();

      if (!localStorage.getItem("profileImageUrl")) {
        const getUser = () => {
          fetch(
            `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/users/byemail?email=${localStorage.getItem(
              "userEmail"
            )}`,
            {
              headers: {
                Authorization: `${localStorage.getItem("accessToken")}`,
              },
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((result) => {
              localStorage.setItem("profileImageUrl", result.profileImageUrl);
            });
        };

        getUser();
      }
    }
  }, []);

  const showOptions = dropdownOpen && inputValue.length > 0;

  const handleOptionSelected = (event, value) => {
    if (value) {
      // Assuming `value` has a property that determines the navigation path
      navigate(`/project/${value.id}`);
    }
  };

  const handleNotificationReset = async () => {
    setUnreadCount(0);
    const updateData = {
      userId: localStorage.getItem("userId"),
    };
    try {
      const response = await fetch(
        "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/notifications",
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "PUT",
          body: JSON.stringify(updateData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigateToHome = () => {
    navigate("/");
  };

  const navigateToChat = () => {
    handleNotificationReset();
    navigate("/chat");
  };

  const navigateToSettings = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/settings");
  };

  const navigateToProfile = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    let userId = localStorage.getItem("userId");
    navigate(`/profile/${userId}`);
  };

  const navigateToMyProjects = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/projects");
  };

  const navigateToMyFavorites = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/favorites");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSignOut = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.signOut();
    }
    updateProfileImageUrl("");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userId");
    localStorage.removeItem("birthday");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("following");
    localStorage.removeItem("profileImageUrl");
    navigate("/login");
  };

  const handleSignIn = () => {
    navigate("/login");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = localStorage.getItem("accessToken") ? (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={navigateToProfile}>Profile</MenuItem>
      <MenuItem onClick={navigateToMyProjects}>Projects</MenuItem>
      <MenuItem onClick={navigateToMyFavorites}>Favorites</MenuItem>
      <MenuItem onClick={navigateToSettings}>My Account</MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleSignIn}>Sign In</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = localStorage.getItem("accessToken") ? (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <ChatIcon onClick={navigateToChat} />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ffffff",
          boxShadow: "none",
        }}
      >
        <Toolbar className="custom-margins" sx={{ height: "100px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <img
                alt="togather text logo"
                onClick={navigateToHome}
                src="/images/togather_text_logo.svg"
                className="cursor"
              ></img>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="togather logo"
                src="/images/togather_logo.svg"
                sx={{
                  display: { xs: "none", md: "flex" },
                  mr: 2,
                  cursor: "pointer",
                }}
                onClick={navigateToHome}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <Autocomplete
                disablePortal
                freeSolo
                ListboxProps={{
                  style: {
                    maxHeight: 100,
                    fontFamily: "Epilogue",
                    fontSize: "12px",
                  },
                }}
                options={[...projects]}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  if (!inputValue) {
                    return [];
                  }
                  return defaultFilterOptions(options, params);
                }}
                onChange={handleOptionSelected} // Add the onChange event handler
                getOptionLabel={(option) => option.projectName || ""}
                onOpen={() => setDropdownOpen(true)}
                onClose={() => setDropdownOpen(false)}
                sx={{
                  width: "200px",
                  marginLeft: "20px",
                  "& .MuiInputBase-root": {
                    fontFamily: "Epilogue",
                    fontSize: "12px",
                    height: "40px",
                    backgroundColor: "#F2F2F2",
                    width: "100%",
                    padding: "2px 4px 4px 12px",
                    borderRadius: showOptions ? "20px 20px 0 0" : "40px", // Conditional border-radius
                  },
                  "& .Mui-focused fieldset": {
                    borderWidth: "0px !important", // Adjust border-width when focused
                  },
                  "& fieldset": {
                    borderWidth: "0px !important", // Adjust border-width when focused
                  },
                }}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props}>
                      {option.projectName}
                    </Box>
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(renderInputParams) => (
                  <div
                    ref={renderInputParams.InputProps.ref}
                    style={{
                      alignItems: "center",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      style={{ flex: 1 }}
                      InputProps={{
                        ...renderInputParams.InputProps,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ color: "#9F7FE3" }}
                          >
                            {" "}
                            <Search />{" "}
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search"
                      inputProps={{
                        ...renderInputParams.inputProps,
                      }}
                      InputLabelProps={{ style: { display: "none" } }}
                    />
                  </div>
                )}
              />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {localStorage.getItem("accessToken") && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <IconButton
                      size="large"
                      aria-label="show new mails"
                      onClick={navigateToChat}
                      id="notif-button"
                      aria-controls={open ? "notif-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Badge badgeContent={unreadCount} color="error">
                        <ChatIcon sx={{ color: "#333333" }} />
                      </Badge>
                    </IconButton>
                    <div>
                      <IconButton
                        size="large"
                        aria-label="show new notifications"
                        onClick={handleClick}
                      >
                        <Badge badgeContent={notificationsCount} color="error">
                          <NotificationsIcon sx={{ color: "#333333" }} />
                        </Badge>
                      </IconButton>
                      <Menu
                        id="notif-menu"
                        anchorEl={anchorNotifs}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {notifications &&
                          notifications.map((notification) => {
                            return (
                              <MenuItem onClick={handleClose}>
                                {notification.content}
                              </MenuItem>
                            );
                          })}
                      </Menu>
                    </div>
                  </Box>
                )}

                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="#ffffff"
                >
                  <Avatar
                    sx={{ maxWidth: "30px", maxHeight: "30px" }}
                    src={
                      profileImageUrl !== "none" && profileImageUrl
                        ? cdn + profileImageUrl
                        : null
                    }
                  />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </React.Fragment>
  );
};

export default TopAppBar;
