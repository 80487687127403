import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const userPoolId = "us-east-1_nhYQZ6Jva";
const appClientId = "4ffdqcgi7f9tleqkpj80dc5irr";

const poolData = {
  UserPoolId: userPoolId,
  ClientId: appClientId,
};

const userPool = new CognitoUserPool(poolData);

const SignUp = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [disableButton, setDisableButton] = useState();
  const [showPassError, setShowPassError] = useState(false);
  const [showConfirmPassError, setShowConfirmPassError] = useState(false);
  const [stage, setStage] = useState("signup");
  const [error, setError] = useState();
  const [code, setCode] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState(null);
  const navigate = useNavigate();

  const handleSignUp = async () => {
    const attributeList = [];
    const dataEmail = {
      Name: "email",
      Value: email,
    };

    const dataBirthday = {
      Name: "custom:birthdate",
      Value: dayjs(birthday).format("M/D/YY"),
    };

    const dataFirstName = {
      Name: "custom:firstName",
      Value: firstName,
    };

    const dataLastName = {
      Name: "custom:lastName",
      Value: lastName,
    };

    const attributeBirthday = new CognitoUserAttribute(dataBirthday);
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeFirstName = new CognitoUserAttribute(dataFirstName);
    const attributeLastName = new CognitoUserAttribute(dataLastName);

    attributeList.push(attributeEmail);
    attributeList.push(attributeBirthday);
    attributeList.push(attributeFirstName);
    attributeList.push(attributeLastName);

    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      const cognitoUser = result.user;
      setStage("confirm");
    });
  };

  const handleSignIn = () => {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        localStorage.setItem("accessToken", result.idToken.jwtToken);
        localStorage.setItem("userEmail", result.idToken.payload.email);
        localStorage.setItem("userId", result.idToken.payload.sub);
        localStorage.setItem(
          "birthday",
          result.idToken.payload["custom:birthdate"]
        );
        localStorage.setItem(
          "firstName",
          result.idToken.payload["custom:firstName"]
        );
        localStorage.setItem(
          "lastName",
          result.idToken.payload["custom:lastName"]
        );

        navigate("/");
      },
      onFailure: (err) => {
        console.error(err);
      },
    });
  };

  const handleVerify = () => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        console.error(err);
        setError("Verification failed. Please try again.");
        return;
      }
      setError("");
      handleSignIn();
      // Redirect user or update UI as needed
    });
  };

  const isPasswordValid = () => {
    const passwordPattern =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter."
      );
      setShowPassError(true);
      return false;
    }
    setPasswordError("");
    setShowPassError(false);
    return true;
  };

  const handleConfirmPassword = (value) => {
    setConfirmPassword(value);
    if (password === value) {
      setConfirmPasswordError("");
      setShowConfirmPassError(false);
      return true;
    } else {
      setConfirmPasswordError("Password does not match");
      setShowConfirmPassError(true);
    }
  };

  const handleSignOut = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      console.log("User signed out.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignIn(event);
    }
  };

  useEffect(() => {
    if (
      email &&
      password &&
      confirmPassword &&
      !passwordError &&
      !confirmPasswordError
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [email, password, confirmPassword, passwordError, confirmPasswordError]);

  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Grid
        container
        spacing={0}
        sx={{ height: "100%", backgroundColor: "#564a92" }}
      >
        <Grid
          item
          xs={6}
          sx={{
            padding: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#ffffff",
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
          }}
        >
          <Card sx={{ width: 500, padding: 3, boxShadow: "none" }}>
            <CardMedia
              component="img"
              src="/images/Togather_v.png"
              alt="togather logo"
              sx={{
                width: 120,
                height: "auto",
                display: "unset",
                marginBottom: "20px",
              }}
            />
            <CardContent>
              {stage === "signup" && (
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                >
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        label="Email"
                        variant="outlined"
                        value={email || ""}
                        sx={{ width: "100%" }}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          required
                          value={birthday}
                          onChange={(newValue) => {
                            setBirthday(newValue);
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        label="First Name"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={firstName || ""}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        label="Last Name"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={lastName || ""}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        label="Password"
                        type="password"
                        sx={{ width: "100%" }}
                        variant="outlined"
                        value={password || ""}
                        onBlur={isPasswordValid}
                        onChange={(e) => setPassword(e.target.value)}
                        error={showPassError}
                      />
                      {passwordError && (
                        <Typography
                          variant="caption"
                          sx={{
                            fontWeight: "400",
                            color: "#d32f2f",
                            width: "251px !important",
                          }}
                        >
                          {passwordError}{" "}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        sx={{ width: "100%" }}
                        label="Confirm Password"
                        type="password"
                        variant="outlined"
                        value={confirmPassword || ""}
                        onChange={(e) => handleConfirmPassword(e.target.value)}
                        error={showConfirmPassError}
                      />
                      {confirmPasswordError && (
                        <Typography
                          variant="caption"
                          sx={{
                            fontWeight: "400",
                            color: "#d32f2f",
                            width: "251px !important",
                          }}
                        >
                          {confirmPasswordError}{" "}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
              {stage === "confirm" && (
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  noValidate
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    Please check your email
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: "400",
                      color: "#000000",
                    }}
                  >
                    We've sent a code to {email}
                  </Typography>
                  <TextField
                    label="Code"
                    variant="outlined"
                    value={code || ""}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Box>
              )}
              {error && (
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "400",
                    color: "#000000",
                  }}
                >
                  {error}
                </Typography>
              )}
            </CardContent>
            <CardActions
              sx={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {stage === "signup" && (
                <Button
                  onClick={handleSignUp}
                  disabled={disableButton}
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#564a92",
                    ":hover": {
                      bgcolor: "#564a92b8",
                    },
                    borderRadius: "22px",
                  }}
                >
                  Sign Up
                </Button>
              )}
              {stage === "confirm" && (
                <Button
                  onClick={handleVerify}
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#564a92",
                    ":hover": {
                      bgcolor: "#564a92b8",
                    },
                    borderRadius: "22px",
                  }}
                >
                  Verify
                </Button>
              )}
              <Button
                sx={{
                  marginTop: "20px",
                  "&:hover": {
                    background: "none",
                  },
                  textTransform: "none",
                  color: "#7e7e7e",
                }}
                variant="text"
                onClick={() => navigate("/login")}
              >
                Log In
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#564a92",
          }}
        >
          <img
            src="/images/home_image.png"
            alt="Togather Logo"
            width={450}
            height={"auto"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUp;
