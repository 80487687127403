import React from "react";
import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MessageContainer from "./MessageContainer";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import DOMPurify from "dompurify";
import CircularProgress from "@mui/material/CircularProgress";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#E7E7E7",
  marginRight: "30px",
  marginLeft: "30px",
  marginBottom: "20px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

const Chat = ({ ws }) => {
  const [message, setMessage] = useState("");
  const [showNewChat, setShowNewChat] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState("");
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [currentThreadHeader, setCurrentThreadHeader] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const messagesEndRef = useRef(null);

  const currentThreadIdRef = useRef(currentThreadId);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const linkify = (text) => {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );
  };

  const handleMessageChange = (message) => {
    const newMessage = linkify(message);

    setMessage(newMessage);
  };

  const renderSenderMessageContent = (content) => {
    // Regular expression to match URLs
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    // Replace URLs in the content with anchor tags
    const linkifiedContent = content.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );

    // Sanitize the linkified content
    const sanitizedContent = DOMPurify.sanitize(linkifiedContent, {
      ADD_ATTR: ["target"],
    });

    // Return the content as dangerously set HTML
    return (
      <div
        className="sender-type"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  const renderRecMessageContent = (content) => {
    // Regular expression to match URLs
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    // Replace URLs in the content with anchor tags
    const linkifiedContent = content.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );

    // Sanitize the linkified content
    const sanitizedContent = DOMPurify.sanitize(linkifiedContent, {
      ADD_ATTR: ["target"],
    });

    // Return the content as dangerously set HTML
    return (
      <div
        className="rec-type"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  useEffect(() => {
    currentThreadIdRef.current = currentThreadId;
  }, [currentThreadId]);

  useEffect(() => {
    ws.onmessage = (event) => {
      console.log("Message received:", event.data);
      const messageObject = JSON.parse(event.data);
      if (messageObject.type !== "message") {
        return;
      }
      const newMessage = {
        content: messageObject.message,
        sender: messageObject.sender,
        messageId: messageObject.messageId,
        createdAt: Date.now(),
        threadId: messageObject.threadId,
      };

      // Update threads state
      setThreads((prevThreads) => {
        const threadExists = prevThreads.some(
          (thread) => thread.threadId === messageObject.threadId
        );

        if (!threadExists) {
          const newThread = {
            threadId: messageObject.threadId,
            lastMessageBody: messageObject.message,
            lastMessageSender: messageObject.sender,
            updatedAt: messageObject.createdAt,
            unreadMessagesCount: 1,
            participants: [
              { ...messageObject.sender },
              {
                userEmail: localStorage.getItem("userEmail"),
                userName: `${localStorage.getItem(
                  "firstName"
                )} ${localStorage.getItem("lastName")}`,
                userId: localStorage.getItem("userId"),
                profileImageUrl: localStorage.getItem("profileImageUrl"),
              },
            ],
          };
          return [...prevThreads, newThread];
        } else {
          return prevThreads.map((thread) => {
            if (thread.threadId === messageObject.threadId) {
              const isActiveThread =
                messageObject.threadId === currentThreadIdRef.current;
              return {
                ...thread,
                lastMessageBody: messageObject.message,
                lastMessageSender: messageObject.sender,
                updatedAt: messageObject.createdAt,
                unreadMessagesCount: isActiveThread
                  ? thread.unreadMessagesCount
                  : (thread.unreadMessagesCount || 0) + 1,
              };
            }
            return thread;
          });
        }
      });

      // Update messages for the current thread
      if (messageObject.threadId === currentThreadIdRef.current) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    };
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]); // Replace 'messages' with your actual messages state or prop

  useEffect(() => {
    getThreads();
  }, []);

  const handleNewChat = () => {
    setShowNewChat(true);
    setCurrentThreadId(uuidv4());
    setMessages([]);
  };

  const getThreads = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/threads/user?id=${localStorage.getItem(
        "userId"
      )}`,
      {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Threads:", data);
        setShowSkeleton(false);
        setThreads(data);
        if (data.length > 0) {
          changeThreadHandler(data[0]);
          updateLastMessageHandler(data[0]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeThreadHandler = async (thread) => {
    setMessages([]);
    setLoading(true);
    handleRecipientChange(null, thread.participants);
    setCurrentThreadId(thread.threadId);
    getMessages(thread.threadId);
    const currentUserEmail = localStorage.getItem("userEmail");
    // Find the first participant whose email is not equal to currentUserEmail
    const firstNonCurrentUserParticipant = thread.participants.find(
      (participant) => participant.userEmail !== currentUserEmail
    );
    setCurrentThreadHeader(firstNonCurrentUserParticipant);
  };

  const getMessages = (id) => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/messages?id=${id}`,
      {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("messages:", data);
        setLoading(false);
        setMessages(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sendMessage = async () => {
    let currentUser = {
      userId: localStorage.getItem("userId"),
      userEmail: localStorage.getItem("userEmail"),
      userName: `${localStorage.getItem("firstName")} ${localStorage.getItem(
        "lastName"
      )}`,
      profileImageUrl: localStorage.getItem("profileImageUrl")
        ? localStorage.getItem("profileImageUrl")
        : "none",
    };
    if (showNewChat) {
      let data = {
        members: recipients,
        threadId: currentThreadId,
      };

      data.members = [...data.members, currentUser];
      try {
        const response = await fetch(
          "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/members",
          {
            headers: {
              Authorization: `${localStorage.getItem("accessToken")}`,
            },
            method: "POST",
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setShowNewChat(false);
      } catch (error) {
        console.error("Add members failed:", error);
      }
    }
    let newMessageId = uuidv4();

    if (ws) {
      const messageObject = {
        type: "message",
        content: message,
        recipients,
        threadId: currentThreadId,
        messageSender: currentUser,
        messageId: newMessageId,
      };

      ws.send(JSON.stringify(messageObject));
    }

    setMessage("");

    let newMessage = {
      content: message,
      sender: currentUser,
      createdAt: Date.now(),
      messageId: newMessageId,
      threadId: currentThreadId,
    };

    setMessages([...messages, newMessage]);
  };

  const filteredThreads = threads.filter((thread) => {
    // Check if any participant's name matches the search query
    const isParticipantMatch = thread.participants.some((participant) =>
      participant.userName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Check if the last message body matches the search query
    const isMessageMatch = thread.lastMessageBody
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    return isParticipantMatch || isMessageMatch;
  });

  const updateLastMessageHandler = async (thread) => {
    console.log("look here: ", thread);
    if (thread.unreadMessagesCount > 0) {
      setThreads((prevThreads) =>
        prevThreads.map((t) => {
          if (t.threadId === thread.threadId) {
            return { ...t, unreadMessagesCount: 0 };
          }
          return t;
        })
      );
    }
    const currentUserEmail = localStorage.getItem("userEmail");

    // Find the participant in the thread whose email matches the current user's email
    const participant = thread.participants.find(
      (p) => p.userEmail === currentUserEmail
    );

    // If the participant is not found, you might want to handle this scenario
    if (!participant) {
      return;
    }

    const data = {
      threadId: thread.threadId,
      id: participant.id, // assuming each participant has a unique ID
      lastMessageAt: Date.now(),
    };

    try {
      const response = await fetch(
        "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/participants",
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "PUT",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let followingArray = [];

    const storedFollowing = localStorage.getItem("following");
    if (storedFollowing && storedFollowing !== "undefined") {
      const followingMap = JSON.parse(storedFollowing);
      // Convert map to array
      followingArray = Object.keys(followingMap).map(
        (key) => followingMap[key]
      );
    }

    setUsersList(followingArray);
  }, []);

  const handleRecipientChange = (event, newValue) => {
    setRecipients(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "2rem",
          paddingTop: "2rem",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={5}>
            <Container
              sx={{
                padding: "0px 0px 20px 0px !important",
                marginTop: "30px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
                height: "540px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "500",
                    marginBottom: "20px",
                    paddingTop: "30px",
                    marginLeft: "30px",
                  }}
                >
                  Messages
                </Typography>
                <IconButton
                  sx={{
                    marginRight: "30px",
                    marginTop: "30px",
                    marginBottom: "20px",
                    color: "#564a92",
                  }}
                  onClick={handleNewChat}
                >
                  <MapsUgcIcon
                    sx={{
                      color: "#564a92",
                    }}
                  />
                </IconButton>
              </Box>

              <Search
                sx={{ border: "1px solid #dddddd", borderRadius: "40px" }}
              >
                <SearchIconWrapper>
                  <SearchRoundedIcon
                    sx={{
                      color: "#65676b",
                    }}
                  />
                </SearchIconWrapper>

                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{ color: "#65676b" }}
                />
              </Search>
              {filteredThreads.map((thread) => {
                return (
                  <MessageContainer
                    key={thread.threadId}
                    name={thread.lastMessageSender.userName}
                    message={thread.lastMessageBody}
                    unread={0}
                    time={dayjs(thread.updatedAt).format("h:mm A")}
                    icon={thread.lastMessageSender.profileImageUrl}
                    participants={thread.participants}
                    currentUser={localStorage.getItem("userEmail")}
                    onClick={() => {
                      changeThreadHandler(thread);
                      updateLastMessageHandler(thread);
                    }}
                    unreadCount={thread.unreadMessagesCount}
                  />
                );
              })}
              {showSkeleton && (
                <Stack spacing={0}>
                  <Box
                    key={1}
                    sx={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      padding: "10px 30px 10px 30px",
                    }}
                  >
                    <Skeleton variant="circular" width={56} height={56} />
                    <Stack spacing={1} sx={{ paddingTop: "4px" }}>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "14px", width: "22rem" }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "12px", width: "16rem" }}
                      />
                    </Stack>
                  </Box>
                  <Box
                    key={2}
                    sx={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      padding: "10px 30px 10px 30px",
                    }}
                  >
                    <Skeleton variant="circular" width={56} height={56} />
                    <Stack spacing={1} sx={{ paddingTop: "4px" }}>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "14px", width: "22rem" }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "12px", width: "16rem" }}
                      />
                    </Stack>
                  </Box>
                  <Box
                    key={3}
                    sx={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      padding: "10px 30px 10px 30px",
                    }}
                  >
                    <Skeleton variant="circular" width={56} height={56} />
                    <Stack spacing={1} sx={{ paddingTop: "4px" }}>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "14px", width: "22rem" }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "12px", width: "16rem" }}
                      />
                    </Stack>
                  </Box>
                  <Box
                    key={4}
                    sx={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      padding: "10px 30px 10px 30px",
                    }}
                  >
                    <Skeleton variant="circular" width={56} height={56} />
                    <Stack spacing={1} sx={{ paddingTop: "4px" }}>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "14px", width: "22rem" }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "12px", width: "16rem" }}
                      />
                    </Stack>
                  </Box>
                </Stack>
              )}
            </Container>
          </Grid>
          <Grid item xs={7}>
            <Container
              sx={{
                padding: "30px !important",
                marginTop: "30px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
                textAlign: "center",
                height: "540px",
                position: "relative",
              }}
            >
              {showNewChat ? (
                <Box className="messageHeader">
                  <Autocomplete
                    multiple
                    fullWidth
                    options={usersList}
                    onChange={handleRecipientChange}
                    getOptionLabel={(option) => option.userName}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          avatar={
                            <Avatar
                              alt={option.userName}
                              src={
                                option.profileImageUrl
                                  ? option.profileImageUrl
                                  : null
                              }
                            />
                          }
                          variant="outlined"
                          label={option.userName}
                          {...getTagProps({ index })}
                          // You can add more customization here, like custom delete icons, styles, etc.
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Add User" />
                    )}
                  />
                </Box>
              ) : currentThreadHeader.userName ? (
                <Box className="messageHeader">
                  <Container
                    className="noPadding"
                    sx={{ width: "auto", marginRight: "20px" }}
                  >
                    <Avatar
                      sx={{ width: 56, height: 56 }}
                      alt={currentThreadHeader.userName}
                      src={currentThreadHeader.profileImageUrl}
                    />
                  </Container>
                  <Container
                    className="noPadding"
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                      }}
                    >
                      {currentThreadHeader.userName}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: "400",
                        textTransform: "none",
                      }}
                    >
                      Online
                    </Typography>
                  </Container>
                  <IconButton aria-label="chat menu">
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <Skeleton variant="circular" width={56} height={56} />
                  <Stack spacing={1} sx={{ paddingTop: "4px" }}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "14px", width: "22rem" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "12px", width: "16rem" }}
                    />
                  </Stack>
                </Box>
              )}

              <Divider variant="middle" />
              <Box
                sx={{
                  overflowY: "auto",
                  height: "345px",
                  paddingRight: "10px",
                }}
              >
                {loading && (
                  <CircularProgress
                    color="secondary"
                    sx={{ marginTop: "20px" }}
                  />
                )}
                {messages.map((message) =>
                  message.sender.userId === localStorage.getItem("userId") ? (
                    <Box key={message.messageId} className="recipientContainer">
                      <Container className="recipientBubble">
                        {renderRecMessageContent(message.content)}
                      </Container>
                      <Typography
                        variant="body2"
                        display="block"
                        sx={{
                          fontWeight: "400",
                          textTransform: "none",
                          padding: "0 !important",
                          color: "#707070",
                          textAlign: "right",
                          fontSize: "10px",
                        }}
                      >
                        {dayjs(message.createdAt).format("h:mm A")}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      key={message.messageId}
                      sx={{
                        display: "flex",
                        marginTop: "16px",
                        gap: "10px",
                        alignItems: "end",
                      }}
                    >
                      <Avatar
                        sx={{ width: 26, height: 26, padding: "2px" }}
                        alt={message.sender.userName}
                        src={message.sender.profileImageUrl}
                      />
                      <Box>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{
                            fontWeight: "400",
                            textTransform: "none",
                            padding: "0 !important",
                            color: "#707070",
                            textAlign: "left",
                            fontSize: "10px",
                            marginLeft: "14px",
                            marginBottom: "2px",
                          }}
                        >
                          {message.sender.userName}
                        </Typography>
                        <Box className="senderContainer">
                          <Container className="senderBubble">
                            {renderSenderMessageContent(message.content)}
                          </Container>
                          <Typography
                            variant="body2"
                            display="block"
                            sx={{
                              fontWeight: "400",
                              textTransform: "none",
                              padding: "0 !important",
                              color: "#707070",
                              textAlign: "left",
                              fontSize: "10px",
                              marginLeft: "14px",
                            }}
                          >
                            {dayjs(message.createdAt).format("h:mm A")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )
                )}
                <div ref={messagesEndRef} />
              </Box>

              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "25px",
                  width: "90%",
                  boxShadow: "none !important",
                  backgroundColor: "#564a9214",
                  position: "absolute",
                  bottom: "20px",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <AttachFileIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Type your message here ..."
                  inputProps={{ "aria-label": "write message" }}
                  value={message}
                  onChange={(e) => handleMessageChange(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      // Check if Enter key is pressed without shift
                      e.preventDefault(); // Prevent the default action to avoid newline
                      sendMessage();
                    }
                  }}
                />
                <IconButton
                  onClick={() => sendMessage()}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="send"
                >
                  <SendIcon />
                </IconButton>
              </Paper>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Chat;
