import React, { useState } from "react";
import Container from "@mui/material/Container";

const ProjectRoles = ({ roles }) => {
  const [showAllRoles, setShowAllRoles] = useState(false);
  const handleEllipsesClick = (e) => {
    e.stopPropagation(); // This stops the event from propagating up to parent elements
    setShowAllRoles(true);
  };
  return (
    <div
      style={{
        height: "130px",
        overflowY: "scroll",
        textAlign: "left",
        marginTop: "20px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {!showAllRoles &&
        roles.slice(0, showAllRoles ? roles.length : 4).map((role, index) => (
          <Container
            key={index}
            sx={{
              padding: "0px !important",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="roles" sx={{ marginLeft: "10px" }}>
              {role.label}
            </div>
          </Container>
        ))}

      {!showAllRoles && roles.length > 4 && (
        <button
          style={{
            marginLeft: "15px",
            fontFamily: "Epilogue",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            color: "white",
          }}
          onClick={handleEllipsesClick}
        >
          ...
        </button>
      )}

      {showAllRoles && (
        <div
          style={{
            display: "flex",
            gap: "15px",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "20px",
          }}
          onMouseLeave={() => setShowAllRoles(false)}
        >
          {roles.map((role, index) => (
            <Container
              key={index}
              sx={{
                padding: "0px !important",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="roles" sx={{ marginLeft: "10px" }}>
                {role.label}
              </div>
            </Container>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectRoles;
