import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

let map;

window.initMap = function () {
  if (window.google && window.google.maps) {
    const { Map } = window.google.maps;

    map = new Map(document.getElementById("map"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
    });
  } else {
    console.error("Google Maps library not loaded.");
  }
};

const loadGoogleMaps = () => {
  const script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyCo8gi507rCjvSTOAsPFQbwqhdaYP80Ook&libraries=places&callback=initMap";
  script.async = true;
  document.head.appendChild(script);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

document.addEventListener("DOMContentLoaded", loadGoogleMaps);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
