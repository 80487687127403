import React, { createContext, useState, useContext, useEffect } from "react";

const ProjectsContext = createContext();

export const useProjects = () => useContext(ProjectsContext);

export const ProjectsProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);

  const updateProjects = () => {
    getAllProjects();
  };

  const getAllProjects = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects/all`
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setProjects(result);
      });
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <ProjectsContext.Provider value={{ projects, updateProjects }}>
      {children}
    </ProjectsContext.Provider>
  );
};
