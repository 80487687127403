import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Sortable from "sortablejs";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactPlayer from "react-player";

const ImageItem = React.memo(
  ({ id, content, isSelected, onToggle, isFirstChild, type }) => {
    return (
      <div
        key={id}
        data-id={id}
        draggable
        className={`image-wrapper ${
          isFirstChild ? "first-item" : "other-item"
        } ${isSelected ? "checked" : ""}`}
        onClick={() => onToggle(id)}
      >
        {type === "video" ? (
          <ReactPlayer width={200} height={110} url={content}></ReactPlayer>
        ) : (
          <img src={content} className="image-item" alt={`content-${id}`} />
        )}

        <div className="checkbox-container">
          <Checkbox
            checked={isSelected}
            sx={{
              color: "#ffffff",
              "&.Mui-checked": {
                color: "#000000",
              },
            }}
            size="small"
          />
        </div>
      </div>
    );
  }
);

const SortableTool = ({ sendImages, initialData = [], deleteFromS3 }) => {
  const gridRef = useRef(null);
  // State to track selected items for deletion
  const [selected, setSelected] = useState(new Set());
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");

  // Function to update the state with the new order
  const updateState = useCallback(
    (newOrder) => {
      const newData = newOrder.map((id) => data.find((item) => item.id === id));
      setData(newData);
    },
    [data]
  );

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addVideo = () => {
    setOpen(false);
    if (ReactPlayer.canPlay(url)) {
      const videoItem = {
        id: `video_${Date.now()}`,
        content: url,
        type: "video",
        isNew: true,
      };
      setData((currentData) => [...currentData, videoItem]);
      setUrl("");
    } else {
      alert("Invalid video URL");
    }
  };

  const handleSelectChange = (id) => {
    setSelected((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  // Function to delete selected items
  const deleteSelectedItems = async () => {
    const selectedForDeletion = data.filter(
      (item) => selected.has(item.id) && !item.isNew
    );

    if (selectedForDeletion.length > 0 && deleteFromS3) {
      await deleteFromS3(selectedForDeletion);
    }

    setData((currentData) =>
      currentData.filter((item) => !selected.has(item.id))
    );

    // Reset the selected items
    setSelected(new Set());
  };

  useEffect(() => {
    sendImages(data);
  }, [data, sendImages]);

  useEffect(() => {
    const sortableJsRef = Sortable.create(gridRef.current, {
      onEnd: (evt) => {
        const { oldIndex, newIndex } = evt;
        if (oldIndex !== newIndex) {
          const newOrder = sortableJsRef.toArray();
          updateState(newOrder);
          sessionStorage.setItem("my-grid", JSON.stringify(newOrder));
        }
      },
    });

    // Cleanup function to prevent memory leaks
    return () => {
      sortableJsRef.destroy();
    };
  }, [data, updateState]); // Re-run effect if data changes

  // Function to handle file drop
  const onDrop = useCallback((acceptedFiles) => {
    // Create new items from the accepted files and update the data state
    const newItems = acceptedFiles.map((file, index) => ({
      id: `new_${Date.now()}_${index}`, // Generate a unique ID for each new item
      content: URL.createObjectURL(file), // Create a URL for the dropped file
    }));

    setData((currentData) => [...currentData, ...newItems]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "video/mp4": [".mp4"],
    },
    multiple: true,
  });

  const toggleSelection = (id) => {
    handleSelectChange(id);
  };

  useEffect(() => {
    // Populate the data state with initialData if provided
    if (initialData.length > 0) {
      const initialDataFormatted = initialData.map((url, index) => ({
        id: `existing_${index}`,
        content: url,
        isNew: false, // Flag to indicate this is an existing image
      }));
      setData(initialDataFormatted);
    }
  }, [initialData]);

  return (
    <div>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <p>Drag and drop images here, or click to select images</p>
        <Button
          sx={{
            borderColor: "#564a92",
            ":hover": {
              borderColor: "#564a92b8",
            },
            color: "#564a92",
            borderRadius: "22px",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={handleClickOpen}
        >
          Add from URL
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { width: "50%" }, // Custom width
        }}
      >
        <DialogTitle>Add File from URL</DialogTitle>
        <DialogContent>
          <DialogContentText>Youtube or Vimeo URL</DialogContentText>
          <TextField
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            autoFocus
            margin="dense"
            fullWidth
            variant="standard"
            placeholder="https://"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addVideo}>Add</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ marginTop: "10px" }}>
        <Typography variant="caption">
          Please choose photos carefully. The FIRST IMAGE will be the cover
          photo.
        </Typography>
      </Box>

      <Button
        sx={{
          marginTop: "20px",
          backgroundColor: "#564a92",
          ":hover": {
            bgcolor: "#564a92b8",
          },
        }}
        size="small"
        variant="contained"
        onClick={deleteSelectedItems}
        disabled={selected.size === 0}
      >
        Delete Selected
      </Button>

      <div ref={gridRef} className="grid-container">
        {data.map(({ id, content, type }, index) => (
          <ImageItem
            key={id}
            id={id}
            content={content}
            isSelected={selected.has(id)}
            onToggle={toggleSelection}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

export default SortableTool;
