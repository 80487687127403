import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

const userPoolId = "us-east-1_nhYQZ6Jva";
const appClientId = "4ffdqcgi7f9tleqkpj80dc5irr";

const poolData = {
  UserPoolId: userPoolId,
  ClientId: appClientId,
};

const userPool = new CognitoUserPool(poolData);

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [stage, setStage] = useState("reset");
  const [error, setError] = useState();
  const [code, setCode] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [disableButton, setDisableButton] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [showPassError, setShowPassError] = useState(false);
  const [showConfirmPassError, setShowConfirmPassError] = useState(false);
  const navigate = useNavigate();

  const goToSignUp = () => {
    navigate("/signup");
  };

  const goToSignIn = () => {
    navigate("/login");
  };

  const isPasswordValid = () => {
    const passwordPattern =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter."
      );
      setShowPassError(true);
      return false;
    }
    setPasswordError("");
    setShowPassError(false);
    return true;
  };

  const handleConfirmPassword = (value) => {
    setConfirmPassword(value);
    if (password === value) {
      setConfirmPasswordError("");
      setShowConfirmPassError(false);
      return true;
    } else {
      setConfirmPasswordError("Password does not match");
      setShowConfirmPassError(true);
    }
  };

  const handleReset = () => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        console.log("Password reset request successful", result);
      },
      onFailure: (err) => {
        console.error("Password reset request failed", err);
      },
      inputVerificationCode: (data) => {
        setStage("confirm");
      },
    });
  };

  const handleSignIn = () => {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        localStorage.setItem("accessToken", result.idToken.jwtToken);
        localStorage.setItem("userEmail", result.idToken.payload.email);
        localStorage.setItem("userId", result.idToken.payload.sub);
        localStorage.setItem(
          "birthday",
          result.idToken.payload["custom:birthdate"]
        );
        localStorage.setItem(
          "firstName",
          result.idToken.payload["custom:firstName"]
        );
        localStorage.setItem(
          "lastName",
          result.idToken.payload["custom:lastName"]
        );
        fetch(
          `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/followers?email=${result.idToken.payload.email}`,
          {
            headers: {
              Authorization: result.idToken.jwtToken,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((getResult) => {
            localStorage.setItem("following", getResult.friends);
            navigate("/");
          });
      },
      onFailure: (err) => {
        setStage("confirm");
      },
    });
  };

  const submitNewPassword = () => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => {
        console.log("Password change successful");
        handleSignIn();
      },
      onFailure: (err) => {
        console.error("Password change failed", err);
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignIn(event);
    }
  };

  useEffect(() => {
    if (
      password &&
      confirmPassword &&
      !passwordError &&
      !confirmPasswordError
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [password, confirmPassword, passwordError, confirmPasswordError]);
  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Grid
        container
        spacing={0}
        sx={{ height: "100%", backgroundColor: "#564a92" }}
      >
        <Grid
          item
          xs={6}
          sx={{
            padding: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#ffffff",
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
          }}
        >
          <Card sx={{ width: 400, padding: 3, boxShadow: "none" }}>
            <CardMedia
              component="img"
              src="/images/Togather_v.png"
              alt="togather logo"
              sx={{ width: 120, height: "auto", display: "unset" }}
            />
            <CardContent>
              {stage === "reset" && (
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  noValidate
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                >
                  <TextField
                    label="Email"
                    variant="outlined"
                    value={email || ""}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password || ""}
                    onChange={(e) => setPassword(e.target.value)}
                  /> */}
                </Box>
              )}
              {stage === "confirm" && (
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  noValidate
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    Please check your email
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: "400",
                      color: "#000000",
                    }}
                  >
                    We've sent a code to {email}
                  </Typography>
                  <TextField
                    label="Code"
                    variant="outlined"
                    value={code || ""}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password || ""}
                    onBlur={isPasswordValid}
                    onChange={(e) => setPassword(e.target.value)}
                    error={showPassError}
                  />
                  {passwordError && (
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: "400",
                        color: "#d32f2f",
                        width: "251px !important",
                      }}
                    >
                      {passwordError}{" "}
                    </Typography>
                  )}
                  <TextField
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    value={confirmPassword || ""}
                    onChange={(e) => handleConfirmPassword(e.target.value)}
                    error={showConfirmPassError}
                  />
                  {confirmPasswordError && (
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: "400",
                        color: "#d32f2f",
                        width: "251px !important",
                      }}
                    >
                      {confirmPasswordError}{" "}
                    </Typography>
                  )}
                </Box>
              )}
              {error && (
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "400",
                    color: "#000000",
                  }}
                >
                  {error}
                </Typography>
              )}
            </CardContent>
            <CardActions
              sx={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {stage === "reset" && (
                <Button
                  onClick={handleReset}
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#564a92",
                    ":hover": {
                      bgcolor: "#564a92b8",
                    },
                    borderRadius: "22px",
                  }}
                >
                  Reset
                </Button>
              )}
              {stage === "confirm" && (
                <Button
                  onClick={submitNewPassword}
                  disabled={disableButton}
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#564a92",
                    ":hover": {
                      bgcolor: "#564a92b8",
                    },
                    borderRadius: "22px",
                  }}
                >
                  Reset Password
                </Button>
              )}
              <Button
                sx={{
                  "&:hover": {
                    background: "none",
                  },
                  textTransform: "none",
                  color: "#7e7e7e",
                  marginTop: "6px",
                }}
                variant="text"
                onClick={goToSignIn}
              >
                Log In
              </Button>
              <Button
                sx={{
                  "&:hover": {
                    background: "none",
                  },
                  textTransform: "none",
                  color: "#7e7e7e",
                }}
                variant="text"
                onClick={goToSignUp}
              >
                Sign Up
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#564a92",
          }}
        >
          <img
            src="/images/home_image.png"
            alt="Togather Logo"
            width={450}
            height={"auto"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
