import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "yet-another-react-lightbox/styles.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Divider from "@mui/material/Divider";
import AppsIcon from "@mui/icons-material/Apps";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import TodayIcon from "@mui/icons-material/Today";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Map from "./Map";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { v4 as uuidv4 } from "uuid";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ReactPlayer from "react-player";

const cdn = "https://d71lxjj3xpfs2.cloudfront.net/";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      cursor: "pointer",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectPage = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState("");
  const { projectId } = useParams();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openFavSnackBar, setOpenFavSnackBar] = useState(false);
  const [images, setImages] = useState([]);
  const [lightBox, setLightBox] = useState([]);
  const [customNote, setCustomNote] = useState("");
  const [currentRoleId, setCurrentRoleId] = useState("");
  const [currentRoleName, setCurrentRoleName] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const location = useLocation();
  const data = location.state;
  const [appliedRoles, setAppliedRoles] = useState([]);
  const [openAppForm, setOpenAppForm] = useState(false);
  const [showApply, setShowApply] = useState(true);

  const navigateToProfile = (userId) => {
    navigate(`/profile/${userId}`);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleAppFormOpen = () => {
    setOpenAppForm(true);
  };

  const handleAppFormClose = () => {
    setOpenAppForm(false);
  };

  const transformArrayImageList = (array) => {
    return array.map((item, index) => ({
      img: cdn + item,
    }));
  };

  const calculateDuration = (duration, durationType) => {
    if (durationType === "Hours") {
      if (duration === "1") {
        return `${duration} Hour`;
      } else {
        return `${duration} Hours`;
      }
    } else if (durationType === "Days") {
      if (duration === "1") {
        return `${duration} Day`;
      } else {
        return `${duration} Days`;
      }
    } else if (durationType === "Weeks") {
      if (duration === "1") {
        return `${duration} Week`;
      } else {
        return `${duration} Weeks`;
      }
    } else if (durationType === "Months") {
      if (duration === "1") {
        return `${duration} Month`;
      } else {
        return `${duration} Months`;
      }
    }
  };

  const transformArrayLightBox = (array) => {
    return array.map((item, index) => {
      // Check if the item is a playable video
      if (ReactPlayer.canPlay(item)) {
        return {
          type: "custom-slide",
          url: item,
        };
      } else {
        // Handle non-video items
        return {
          src: cdn + item,
        };
      }
    });
  };

  const applyToRole = (id, name) => {
    setOpenAppForm(true);
    setCurrentRoleId(id);
    setCurrentRoleName(name);
    console.log(id);
  };

  const getProject = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects?id=${projectId}`
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log(result);
        setProject(result);
        setImages(transformArrayImageList(result.projectMedia));
        setLightBox(transformArrayLightBox(result.projectMedia));
        checkForFavorite(result.id);
        if (result.projectUserEmail === localStorage.getItem("userEmail")) {
          setShowApply(false);
        }
      });
  };

  useEffect(() => {
    getProject();
    setAppliedRoles(localStorage.getItem("appliedRoles") || []);
  }, [projectId]);

  const handleShareClick = () => {
    const currentUrl = window.location.href; // Get the current URL
    navigator.clipboard
      .writeText(currentUrl) // Copy the URL to the clipboard
      .then(() => {
        setOpenSnackBar(true);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleFavClick = () => {
    setOpenFavSnackBar(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleFavSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFavSnackBar(false);
  };

  const handleImageClick = (index) => {
    setIndex(index);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoleApp = async () => {
    const now = new Date();
    const utcString = now.toISOString(); // Converts to ISO 8601 format in UTC
    const applicationData = {
      projectId: project.id,
      applicantName: `${localStorage.getItem(
        "firstName"
      )} ${localStorage.getItem("lastName")}`,
      createdAt: utcString,
      id: `${currentRoleId}-${localStorage.getItem("userEmail")}`,
      applicantEmail: localStorage.getItem("userEmail"),
      customNote,
      name: currentRoleName,
      roleId: currentRoleId,
      applicantId: localStorage.getItem("userId"),
    };
    try {
      const response = await fetch(
        "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/roleapps",
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "POST",
          body: JSON.stringify(applicationData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Application was successful:", result);
      setOpenAppForm(false);
      setCustomNote("");
      let appliedRoles = localStorage.getItem("appliedRoles");

      // Parse the string to an array, or initialize as an empty array if null
      appliedRoles = appliedRoles ? JSON.parse(appliedRoles) : [];

      // Add the new ID to the array if it's not already included
      if (!appliedRoles.includes(currentRoleId)) {
        appliedRoles.push(currentRoleId);
      }

      // Save the updated array back to localStorage
      localStorage.setItem("appliedRoles", JSON.stringify(appliedRoles));
      setAppliedRoles([...appliedRoles, currentRoleId]);
    } catch (error) {
      console.error("Create project failed:", error);
    }
  };

  const handleFavorites = async () => {
    if (isFavorite) {
      await removeFromFavorites();
    } else {
      await addToFavorites();
    }
  };

  const removeFromFavorites = async () => {
    setIsFavorite(false);
    try {
      const response = await fetch(
        `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/favorites?id=${
          project.id
        }-${localStorage.getItem("userEmail")}-fav`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      let favorites = JSON.parse(sessionStorage.getItem("favorites"));
      if (!favorites) {
        favorites = []; // Initialize as an empty array if it doesn't exist
      }

      // Remove the project.id from the array
      favorites = favorites.filter(
        (favorite) =>
          favorite !== `${project.id}-${localStorage.getItem("userEmail")}-fav`
      );

      // Store the updated array back into sessionStorage
      sessionStorage.setItem("favorites", JSON.stringify(favorites));
    } catch (error) {
      console.error("Add to favorites failed:", error);
    }
  };

  const addToFavorites = async () => {
    const now = new Date();
    const utcString = now.toISOString(); // Converts to ISO 8601 format in UTC
    const id = uuidv4();
    const data = {
      projectId: project.id,
      userName: `${localStorage.getItem("firstName")} ${localStorage.getItem(
        "lastName"
      )}`,
      createdAt: utcString,
      id: `${project.id}-${localStorage.getItem("userEmail")}-fav`,
      userEmail: localStorage.getItem("userEmail"),
    };
    setIsFavorite(true);
    try {
      const response = await fetch(
        "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/favorites",
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      let favorites = JSON.parse(sessionStorage.getItem("favorites"));
      if (!favorites) {
        favorites = []; // Initialize as an empty array if it doesn't exist
      }

      favorites.push(`${project.id}-${localStorage.getItem("userEmail")}-fav`);

      // Store the updated array back into sessionStorage
      sessionStorage.setItem("favorites", JSON.stringify(favorites));
    } catch (error) {
      console.error("Add to favorites failed:", error);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackBarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const favAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleFavSnackBarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const checkForFavorite = (id) => {
    const favorites = JSON.parse(sessionStorage.getItem("favorites")) || [];

    // Check if projectId exists in the favorites array
    const isFavorite = favorites.includes(
      `${id}-${localStorage.getItem("userEmail")}-fav`
    );
    console.log("favorites array: ", favorites);
    console.log(`${id}-${localStorage.getItem("userEmail")}-fav`);
    if (isFavorite) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {project ? (
        <Box
          className="custom-margins"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "3rem",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "left", fontWeight: "300" }}
              >
                {project.projectName}
              </Typography>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  padding: "0 !important",
                }}
              >
                <Typography mr={2} variant="body2" color="text.secondary">
                  By: {project.projectOwner}
                </Typography>

                <Typography mr={2} variant="body2" sx={{ fontWeight: "700" }}>
                  {" "}
                  {project.projectCurrentRole &&
                    project.projectCurrentRole.RoleName}
                </Typography>
                <Chip
                  sx={{ backgroundColor: "#ffffff", marginRight: "2px" }}
                  icon={<GroupsIcon />}
                  label={
                    project.projectRolesArray &&
                    `${project.projectRolesArray.length} people`
                  }
                />
                <Chip
                  sx={{ backgroundColor: "#ffffff" }}
                  icon={<TimelapseIcon />}
                  label={calculateDuration(
                    project.projectDurationQuantity,
                    project.projectDurationType
                  )}
                />
              </Container>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    color: "#222222",
                    height: "30px",
                    borderRadius: "12px",
                    ":hover": {
                      bgcolor: "#ecececa8",
                    },
                    textTransform: "none",
                    padding: "16px",
                  }}
                  variant="text"
                  onClick={handleShareClick}
                  startIcon={<IosShareIcon />}
                >
                  Share
                </Button>
                <Snackbar
                  open={openSnackBar}
                  autoHideDuration={3000}
                  onClose={handleSnackBarClose}
                  message="Link copied"
                  action={action}
                />
                <Button
                  sx={{
                    color: "#222222",
                    height: "30px",
                    borderRadius: "12px",
                    ":hover": {
                      bgcolor: "#ecececa8",
                    },
                    textTransform: "none",
                    padding: "16px",
                  }}
                  variant="text"
                  onClick={handleFavorites}
                  startIcon={
                    isFavorite ? (
                      <FavoriteIcon sx={{ color: "red" }} />
                    ) : (
                      <FavoriteBorderIcon />
                    )
                  }
                >
                  Save
                </Button>
                <Snackbar
                  open={openFavSnackBar}
                  autoHideDuration={3000}
                  onClose={handleFavSnackBarClose}
                  message="Project saved"
                  action={favAction}
                />
              </Box>
            </Box>
          </Box>

          <Grid sx={{ marginBottom: "40px" }} container spacing={1}>
            {project.projectMedia && project.projectMedia.length > 0 && (
              <Grid
                sx={{
                  width: "560px",
                  height: "344px",
                  position: "relative",
                  overflow: "hidden",
                }}
                item
                xs={6}
                onClick={handleClickOpen}
              >
                {ReactPlayer.canPlay(project.projectMedia[0]) ? (
                  <ReactPlayer
                    width={596}
                    height={336}
                    url={project.projectMedia[0]}
                  ></ReactPlayer>
                ) : (
                  <img
                    className="child-img"
                    src={cdn + project.projectMedia[0]}
                    alt="cover"
                  ></img>
                )}
              </Grid>
            )}

            <Grid item xs={6}>
              <Grid container spacing={1}>
                {project.projectMedia && project.projectMedia.length > 1 && (
                  <Grid
                    sx={{
                      width: "272px",
                      height: "172px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    item
                    xs={6}
                    onClick={handleClickOpen}
                  >
                    {ReactPlayer.canPlay(project.projectMedia[1]) ? (
                      <ReactPlayer
                        width={294}
                        height={164}
                        url={project.projectMedia[1]}
                      ></ReactPlayer>
                    ) : (
                      <img
                        className="child-img"
                        src={cdn + project.projectMedia[1]}
                        alt="second"
                      ></img>
                    )}
                  </Grid>
                )}
                {project.projectMedia && project.projectMedia.length > 2 && (
                  <Grid
                    sx={{
                      width: "272px",
                      height: "172px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    item
                    xs={6}
                    onClick={handleClickOpen}
                  >
                    {ReactPlayer.canPlay(project.projectMedia[2]) ? (
                      <ReactPlayer
                        width={294}
                        height={164}
                        url={project.projectMedia[2]}
                      ></ReactPlayer>
                    ) : (
                      <img
                        className="child-img"
                        src={cdn + project.projectMedia[2]}
                        alt="third"
                      ></img>
                    )}
                  </Grid>
                )}
                {project.projectMedia && project.projectMedia.length > 3 && (
                  <Grid
                    sx={{
                      width: "272px",
                      height: "172px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    item
                    xs={6}
                    onClick={handleClickOpen}
                  >
                    {ReactPlayer.canPlay(project.projectMedia[3]) ? (
                      <ReactPlayer
                        width={294}
                        height={164}
                        url={project.projectMedia[3]}
                      ></ReactPlayer>
                    ) : (
                      <img
                        className="child-img"
                        src={cdn + project.projectMedia[3]}
                        alt="fourth"
                      ></img>
                    )}
                  </Grid>
                )}
                {project.projectMedia && project.projectMedia.length > 4 && (
                  <Grid
                    sx={{
                      width: "272px",
                      height: "172px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    item
                    xs={6}
                    onClick={handleClickOpen}
                  >
                    {ReactPlayer.canPlay(project.projectMedia[4]) ? (
                      <ReactPlayer
                        width={294}
                        height={164}
                        url={project.projectMedia[4]}
                      ></ReactPlayer>
                    ) : (
                      <img
                        className="child-img"
                        src={cdn + project.projectMedia[4]}
                        alt="fifth"
                      ></img>
                    )}
                  </Grid>
                )}
              </Grid>
              {project.projectMedia && project.projectMedia.length > 5 && (
                <Button
                  onClick={handleClickOpen}
                  className="img-btn"
                  variant="outlined"
                  startIcon={<AppsIcon />}
                  sx={{ textTransform: "none" }}
                >
                  Show all photos
                </Button>
              )}
            </Grid>
          </Grid>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <Container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                padding: "20px 0px 0px 20px !important",
                marginBottom: "30px",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <NavigateBeforeIcon />
              </IconButton>
            </Container>
            <Container sx={{ paddingBottom: "40px" }}>
              <ImageList variant="masonry" cols={3} gap={4}>
                {images &&
                  images.length > 0 &&
                  images.map((item, index) => (
                    <ImageListItem
                      key={index}
                      onClick={() => handleImageClick(index)}
                      sx={{ cursor: "pointer" }}
                    >
                      {ReactPlayer.canPlay(item.img) ? (
                        <ReactPlayer
                          width={380}
                          height={250}
                          url={item.img}
                        ></ReactPlayer>
                      ) : (
                        <img
                          src={`${item.img}?w=248&fit=crop&auto=format`}
                          srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.img}
                          loading="lazy"
                        />
                      )}
                    </ImageListItem>
                  ))}
              </ImageList>
              {lightBox && lightBox.length > 0 && (
                <Lightbox
                  slides={lightBox}
                  plugins={[Video]}
                  open={index >= 0}
                  index={index}
                  close={() => setIndex(-1)}
                  render={{
                    slide: ({ slide }) =>
                      slide.type === "custom-slide" ? (
                        <ReactPlayer
                          width={1280}
                          height={650}
                          url={slide.url}
                        ></ReactPlayer>
                      ) : undefined,
                  }}
                  // enable optional lightbox plugins
                />
              )}
            </Container>
          </Dialog>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Box sx={{ paddingRight: "60px" }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "500",
                    textTransform: "none",
                  }}
                >
                  About the Project
                </Typography>
                <Box
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <TodayIcon sx={{ marginRight: "10px" }} />
                  <Typography
                    variant="button"
                    sx={{
                      textAlign: "left",
                      fontWeight: "400",
                      textTransform: "none",
                    }}
                  >
                    Project Date: {dayjs(project.projectDate).format("M/D/YY")}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    textTransform: "none",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: project.projectDescription,
                  }}
                ></Typography>
                <Divider sx={{ marginTop: "30px", marginBottom: "30px" }} />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "500",
                    textTransform: "none",
                    marginBottom: "20px",
                  }}
                >
                  Location
                </Typography>
                <Box sx={{ width: "auto", height: "400px" }}>
                  {project && project.projectCoordinates && (
                    <Map center={project.projectCoordinates} zoom={14} />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Container
                sx={{
                  padding: "30px !important",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ textAlign: "left", fontWeight: "400" }}
                >
                  Team
                </Typography>
                {project.projectTeam &&
                  project.projectTeam.map((item) => (
                    <Container
                      key={item.id + item.role}
                      sx={{
                        padding: "0px !important",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Avatar
                        onClick={() => navigateToProfile(item.id)}
                        {...stringAvatar(item.name)}
                      />{" "}
                      <Typography
                        onClick={() => navigateToProfile(item.id)}
                        variant="overline"
                        sx={{
                          textAlign: "left",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Chip sx={{ marginLeft: "10px" }} label={item.role} />
                    </Container>
                  ))}
                <Divider sx={{ marginTop: "20px" }} variant="middle" />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    marginTop: "20px",
                  }}
                >
                  Open Roles
                </Typography>
                {project.projectRolesArray &&
                  project.projectRolesArray
                    .filter(
                      (item) =>
                        item.filled === false || item.filled === undefined
                    ) // Filter items where filled is false
                    .map((item) => (
                      <Container
                        key={item.id + item.label}
                        sx={{
                          padding: "0px !important",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Avatar>
                          <PersonIcon />
                        </Avatar>{" "}
                        {showApply && !appliedRoles.includes(item.id) && (
                          <Button
                            sx={{ marginLeft: "10px", color: "#564a92" }}
                            onClick={() => applyToRole(item.id, item.label)}
                          >
                            Apply
                          </Button>
                        )}
                        <Chip sx={{ marginLeft: "10px" }} label={item.label} />
                      </Container>
                    ))}

                <Dialog open={openAppForm} onClose={handleAppFormClose}>
                  <DialogTitle>Apply to Role</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please add a custom note for the project owner. Your
                      profile will be shared once you apply.
                    </DialogContentText>
                    <TextField
                      multiline
                      rows={6}
                      fullWidth
                      sx={{ marginTop: "20px" }}
                      value={customNote}
                      onChange={(e) => setCustomNote(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleAppFormClose}>Cancel</Button>
                    <Button onClick={handleRoleApp}>Apply</Button>
                  </DialogActions>
                </Dialog>
              </Container>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="rounded" width={300} height={70} />
          <Skeleton variant="rounded" width={600} height={40} />
          <Grid sx={{ marginBottom: "40px" }} container spacing={1}>
            <Grid
              sx={{
                width: "560px",
                height: "344px",
                position: "relative",
                overflow: "hidden",
                paddingLeft: "0px !important",
              }}
              item
              xs={6}
            >
              <Skeleton variant="rounded" height={320} />
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "270px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "270px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Skeleton variant="rounded" width={1200} height={300} />
        </Stack>
      )}
    </Box>
  );
};

export default ProjectPage;
