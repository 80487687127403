import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { v4 as uuidv4 } from "uuid";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { checkSession } from "./auth";
//import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css"; // Import quill styles
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SortableTool from "./Sortable";
import LinearProgress from "@mui/material/LinearProgress";
import { handleUpload } from "./imageUtils";
import { useProjects } from "./ProjectsProvider";
import IconButton from "@mui/material/IconButton";
import ReactPlayer from "react-player";
import LinkIcon from "@mui/icons-material/Link";

// let Size = ReactQuill.Quill.import("attributors/style/size");
// Size.whitelist = [
//   "8px",
//   "10px",
//   "12px",
//   "14px",
//   "16px",
//   "18px",
//   "20px",
//   "22px",
//   "24px",
//   "26px",
//   "28px",
//   "30px",
//   "32px",
//   "34px",
//   "36px",
//   "38px",
//   "40px",
// ];
// ReactQuill.Quill.register(Size, true);

// const modules = {
//   toolbar: [
//     // ... (other toolbar options)
//     [{ size: Size.whitelist }],
//     [{ font: [] }], // Dropdown to select font
//     [{ size: ["small", "medium", "large", "huge"] }], // Dropdown to select font size
//     [{ align: [] }], // Dropdown with text align options
//     ["bold", "italic", "underline", "strike"], // Toggles for bold, italic, underline, and strikethrough
//     [{ color: [] }, { background: [] }], // Dropdowns to select text color and background color
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ], // Lists and indentation
//     [{ direction: "rtl" }], // Text direction
//   ],
// };

const CreateProject = () => {
  const navigate = useNavigate();
  const [roleOptions, setRoleOptions] = useState([]);
  const [openDialog, toggleOpen] = useState(false);
  const [openRoleDialog, toggleRoleOpen] = useState(false);
  const [projectDate, setProjectDate] = useState(null);
  const { updateProjects } = useProjects();
  const [dialogValue, setDialogValue] = useState({
    ID: "",
    RoleName: "",
  });
  const [userDialogValue, setUserDialogValue] = useState({
    ID: "",
    RoleName: "",
  });
  const [userRole, setUserRole] = useState(null);
  const [durationQuantity, setDurationQuantity] = useState("");
  const [duration, setDuration] = useState("Days");
  const [rolesArray, setRolesArray] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [description, setDescription] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectLocation, setProjectLocation] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openDuration, setOpenDuration] = useState(false);
  const [images, setImages] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [projectCategory, setProjectCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [links, setLinks] = useState([]);
  const [currentLink, setCurrentLink] = useState("");
  const inputRef = useRef(null);
  const [projectOwner, setProjectOwner] = useState(
    `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`
  );

  const checkForm = () => {
    if (
      projectName &&
      description &&
      projectDate &&
      projectLocation &&
      durationQuantity &&
      duration &&
      currentRole &&
      rolesArray &&
      projectCategory
    ) {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    checkForm();
  }, [
    projectName,
    description,
    projectDate,
    projectLocation,
    durationQuantity,
    duration,
    currentRole,
    rolesArray,
    projectCategory,
  ]);

  const handleImages = (data) => {
    setImages(data);
  };

  const refreshRoles = () => {
    fetch(`https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/roles`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setRoleOptions(result);
      });
  };

  const refreshCategories = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/categories`
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setCategoryOptions(JSON.parse(result.values));
      });
  };

  useEffect(() => {
    async function init() {
      await checkSession();
      refreshRoles();
      refreshCategories();
    }
    init();
  }, []);

  const handleDialogClose = () => {
    setDialogValue({
      ID: uuidv4(),
      RoleName: "",
    });
    toggleOpen(false);
  };

  const handleRoleDialogClose = () => {
    setUserDialogValue({
      ID: uuidv4(),
      RoleName: "",
    });
    toggleRoleOpen(false);
  };

  const handleNewLink = () => {
    setLinks((links) => [...links, currentLink]);
  };

  const handleNewRole = () => {
    setRolesArray((rolesArray) => [
      ...rolesArray,
      {
        id: uuidv4(),
        label: currentRole.RoleName,
        filled: false,
      },
    ]);
  };

  const handleRoleSubmit = (event) => {
    event.preventDefault();
    setRoleOptions((roleOptions) => [
      ...roleOptions,
      {
        ID: uuidv4(),
        RoleName: dialogValue.RoleName,
      },
    ]);
    setCurrentRole(dialogValue);
    handleDialogClose();
  };

  const handleUserRoleSubmit = (event) => {
    event.preventDefault();
    setRoleOptions((roleOptions) => [
      ...roleOptions,
      {
        ID: uuidv4(),
        RoleName: userDialogValue.RoleName,
      },
    ]);
    setUserRole(userDialogValue);
    handleRoleDialogClose();
  };

  const handleDelete = (id) => {
    setRolesArray(rolesArray.filter((role) => role.id !== id));
  };

  const handleLinkDelete = (link) => {
    setLinks(links.filter((item) => item !== link));
  };

  const handleNewProject = async () => {
    setShowProgress(true);
    try {
      const projectId = uuidv4();
      const mediaUrls = await handleUpload({
        images,
        project: projectId,
      }).catch(console.error);
      const now = new Date();
      const utcString = now.toISOString(); // Converts to ISO 8601 format in UTC

      const projectData = {
        projectName: projectName,
        description: description,
        projectDate: projectDate.$d,
        openDate: openDate,
        location: projectLocation,
        openLocation: openLocation,
        durationQuantity: durationQuantity,
        durationType: duration,
        openDuration: openDuration,
        currentRole: currentRole,
        rolesArray: rolesArray,
        userEmail: localStorage.getItem("userEmail"),
        userId: localStorage.getItem("userId"),
        id: projectId,
        media: mediaUrls,
        createdAt: utcString,
        category: projectCategory,
        projectOwner,
      };

      try {
        const response = await fetch(
          "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects",
          {
            headers: {
              Authorization: `${localStorage.getItem("accessToken")}`,
            },
            method: "POST",
            body: JSON.stringify(projectData),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        console.log("Create project was successful:", result);
        setShowProgress(false);
        updateProjects();
        navigate(`/project/${projectId}`);
      } catch (error) {
        console.error("Create project failed:", error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Function to initialize the autocomplete
    const initializeAutocomplete = () => {
      if (!inputRef.current) return;

      // Remove the restriction on types to allow different types of places (like cities, areas)
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        inputRef.current.value = place.formatted_address || place.name; // Use place.name for areas without a formatted_address
        setProjectLocation(place.formatted_address || place.name);
      });
    };

    // Rest of your useEffect and component

    // If the google object is available, initialize the autocomplete
    if (window.google && window.google.maps && window.google.maps.places) {
      initializeAutocomplete();
    } else {
      // Otherwise, listen for the load event
      window.initMap = initializeAutocomplete;
    }
  }, []);

  const handleChange = (event) => {
    setDuration(event.target.value);
  };

  const handleAddItem = (event) => {
    event.stopPropagation();
    toggleOpen(true);
  };

  const handleUserRoleItem = (event) => {
    event.stopPropagation();
    toggleRoleOpen(true);
  };

  const truncateLabel = (label) => {
    return label.length > 10 ? label.substring(0, 10) + "..." : label;
  };

  const openLinkInNewTab = (url) => {
    // Check if the URL is non-empty and valid
    if (url) {
      // Open the URL in a new tab
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const CustomOption = styled("li")(({ theme }) => ({
    [`&.${autocompleteClasses.option}`]: {
      justifyContent: "left",
      backgroundColor: "#f9f9f9",
    },
  }));

  return (
    <Box
      className="divider-shadow"
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: "#ffffff",
        paddingBottom: "50px",
        paddingTop: "50px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          width: "1100px",
        }}
      >
        <div
          style={{
            marginLeft: "60px",
            zIndex: "2",
            height: "40px",
          }}
        >
          <div className="create-project-tab">
            <div className="text-wrapper">Create Project</div>
            <img
              alt="add icon"
              src="/images/add_icon.svg"
              style={{ marginLeft: "20px" }}
            ></img>
          </div>
          <div
            style={{
              width: "180px",
              height: "6px",
              backgroundColor: "white",
            }}
          ></div>
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "left",
            gap: "10px",
          }}
          className="create-project"
        >
          <div style={{ display: "flex", gap: "60px" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "flex-start",
                gap: "10px",
                maxWidth: "380px",
              }}
            >
              <div
                className="grid-tile"
                style={{ height: "380px", width: "380px" }}
              >
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
              <div className="grid-tile">
                <img
                  className="icon"
                  alt="add icon"
                  src="/images/add-circle.svg"
                ></img>
              </div>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <div className="section">Project</div>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <div className="title">Project Title</div>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  value={projectName || ""}
                  onChange={(e) => setProjectName(e.target.value)}
                />
                <div className="title">Project Category</div>

                <Autocomplete
                  freeSolo
                  value={projectCategory}
                  onChange={(event, newValue) => {
                    setProjectCategory(newValue);
                  }}
                  options={categoryOptions.map((option) => option)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <div className="title">Description</div>
                {/* <ReactQuill
                  theme="snow"
                  modules={modules}
                  value={description}
                  onChange={setDescription}
                  style={{ height: "100px" }}
                /> */}
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  multiline
                  rows={6}
                  value={description || ""}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <div className="section">
                  When & <br />
                  Where
                </div>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div className="title">Date</div>
                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      sx={{ marginRight: "5px" }}
                      control={
                        <Checkbox
                          checked={openDate}
                          onChange={(event) =>
                            setOpenDate(event.target.checked)
                          }
                          size="small"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 16 },
                            color: "#564a92",
                            "&.Mui-checked": {
                              color: "#564a92",
                            },
                          }}
                        />
                      }
                      label="Open"
                      componentsProps={{
                        typography: {
                          sx: {
                            fontWeight: "300",
                            fontSize: "12px",
                            fontFamily: "Epilogue",
                          },
                        },
                      }}
                    />
                    <Tooltip
                      title="Once you mark ‘Open’, you need to specify the date after your project is published"
                      placement="right"
                    >
                      <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                    </Tooltip>
                  </FormGroup>
                </Box>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={projectDate}
                    onChange={(newValue) => {
                      setProjectDate(newValue);
                    }}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div className="title">Location</div>

                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      sx={{ marginRight: "5px" }}
                      control={
                        <Checkbox
                          checked={openLocation}
                          onChange={(event) =>
                            setOpenLocation(event.target.checked)
                          }
                          size="small"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 16 },
                            color: "#564a92",
                            "&.Mui-checked": {
                              color: "#564a92",
                            },
                          }}
                        />
                      }
                      label="Open"
                      componentsProps={{
                        typography: {
                          sx: {
                            fontWeight: "300",
                            fontSize: "12px",
                            fontFamily: "Epilogue",
                          },
                        },
                      }}
                    />
                    <Tooltip
                      title="Once you mark ‘Open’, you need to specify the location after your project is published"
                      placement="right"
                    >
                      <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                    </Tooltip>
                  </FormGroup>
                </Box>

                <TextField
                  inputRef={inputRef}
                  placeholder="Address"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div className="title">Duration</div>

                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      sx={{ marginRight: "5px" }}
                      control={
                        <Checkbox
                          checked={openDuration}
                          onChange={(event) =>
                            setOpenDuration(event.target.checked)
                          }
                          size="small"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 16 },
                            color: "#564a92",
                            "&.Mui-checked": {
                              color: "#564a92",
                            },
                          }}
                        />
                      }
                      label="Open"
                      componentsProps={{
                        typography: {
                          sx: {
                            fontWeight: "300",
                            fontSize: "12px",
                            fontFamily: "Epilogue",
                          },
                        },
                      }}
                    />
                    <Tooltip
                      title="Once you mark ‘Open’, you need to specify the duration after your project is published"
                      placement="right"
                    >
                      <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                    </Tooltip>
                  </FormGroup>
                </Box>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id="outlined-number"
                      placeholder="3"
                      type="number"
                      sx={{ marginRight: "20px", width: "100%" }}
                      value={durationQuantity}
                      onChange={(e) => setDurationQuantity(e.target.value)}
                    />
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={duration}
                        onChange={handleChange}
                      >
                        <MenuItem value={"Hours"}>Hours</MenuItem>
                        <MenuItem value={"Days"}>Days</MenuItem>
                        <MenuItem value={"Weeks"}>Weeks</MenuItem>
                        <MenuItem value={"Months"}>Months</MenuItem>
                        <MenuItem value={"Years"}>Years</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <div className="section">Who</div>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <div className="title">Roles</div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    value={currentRole}
                    onChange={(event, newValue) => {
                      setCurrentRole(newValue);
                    }}
                    options={[
                      { RoleName: "Add new item", specialOption: true },
                      ...roleOptions,
                    ]}
                    getOptionLabel={(option) => option.RoleName || ""}
                    sx={{ width: "90%" }}
                    renderOption={(props, option) => {
                      if (option.specialOption) {
                        return (
                          <CustomOption {...props}>
                            <Button
                              sx={{ color: "#564a92" }}
                              startIcon={<AddCircleIcon />}
                              onClick={(event) => handleAddItem(event)}
                            >
                              Add New Role
                            </Button>
                          </CustomOption>
                        );
                      }
                      return (
                        <Box component="li" {...props}>
                          {option.RoleName}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Add Role" />
                    )}
                  />
                  <Dialog open={openDialog} onClose={handleDialogClose}>
                    <form onSubmit={handleRoleSubmit}>
                      <DialogTitle>Add a new role</DialogTitle>
                      <DialogContent>
                        <DialogContentText sx={{ marginBottom: "20px" }}>
                          Did we miss any? Please, add it!
                        </DialogContentText>
                        <TextField
                          autoFocus
                          id="name"
                          value={dialogValue.RoleName}
                          onChange={(event) =>
                            setDialogValue({
                              ...dialogValue,
                              RoleName: event.target.value,
                            })
                          }
                          placeholder="Enter Role"
                          type="text"
                          variant="outlined"
                          fullWidth
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                      </DialogActions>
                    </form>
                  </Dialog>
                  <IconButton
                    sx={{ marginLeft: "5px" }}
                    onClick={handleNewRole}
                    aria-label="add role"
                  >
                    <img alt="add icon" src="/images/add_icon.svg"></img>
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    marginTop: "15px",
                  }}
                >
                  {rolesArray &&
                    rolesArray.map((role) => {
                      return (
                        <Chip
                          key={role.id}
                          label={role.label}
                          className="role"
                          onDelete={() => handleDelete(role.id)}
                          sx={{
                            "& .MuiChip-deleteIcon": {
                              color: "white",
                            },
                            "& .MuiChip-deleteIcon:hover": {
                              color: "#ffffffc7",
                            },
                          }}
                        />
                      );
                    })}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <div className="section">Lead</div>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <div className="title">Name</div>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  value={projectOwner || ""}
                  onChange={(e) => setProjectOwner(e.target.value)}
                />
                <div className="title">Role</div>

                <Autocomplete
                  disablePortal
                  value={userRole}
                  onChange={(event, newValue) => {
                    setUserRole(newValue);
                  }}
                  id="combo-box-demo"
                  options={[
                    { RoleName: "Add new item", specialOption: true },
                    ...roleOptions,
                  ]}
                  getOptionLabel={(option) => option.RoleName || ""}
                  sx={{ width: "100%" }}
                  renderOption={(props, option) => {
                    if (option.specialOption) {
                      return (
                        <CustomOption {...props}>
                          <Button
                            sx={{ color: "#564a92" }}
                            startIcon={<AddCircleIcon />}
                            onClick={(event) => handleUserRoleItem(event)}
                          >
                            Add New Role
                          </Button>
                        </CustomOption>
                      );
                    }
                    return (
                      <Box component="li" {...props}>
                        {option.RoleName}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Choose Role" />
                  )}
                />
                <Dialog open={openRoleDialog} onClose={handleRoleDialogClose}>
                  <form onSubmit={handleUserRoleSubmit}>
                    <DialogTitle>Add a new role</DialogTitle>
                    <DialogContent>
                      <DialogContentText sx={{ marginBottom: "20px" }}>
                        Did we miss any? Please, add it!
                      </DialogContentText>
                      <TextField
                        autoFocus
                        id="name"
                        value={userDialogValue.RoleName}
                        onChange={(event) =>
                          setUserDialogValue({
                            ...userDialogValue,
                            RoleName: event.target.value,
                          })
                        }
                        placeholder="Enter Role"
                        type="text"
                        variant="outlined"
                        fullWidth
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleRoleDialogClose}>Cancel</Button>
                      <Button type="submit">Add</Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </Grid>
              <Grid item xs={4}>
                <div className="section">Links</div>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <div className="title">Moodboard | Reference Links</div>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    multiple
                    defaultValue={[]}
                    options={[]}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        paddingRight: "40px !important",
                      },
                      "& .MuiAutocomplete-input": {
                        paddingRight: "10px !important",
                      },
                    }}
                    freeSolo
                    value={links}
                    onChange={(event, newValue) => {
                      setLinks(newValue);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          className="link-chip"
                          label={truncateLabel(option)}
                          sx={{
                            fontFamily: "Epilogue",
                            fontSize: "12px",
                            borderRadius: "5px",
                            background: "#fff",
                            border: "1px solid #b3b3b3",
                          }}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Add Link" />
                    )}
                  />
                </Box>
                <div
                  style={{
                    display: "flex",
                    alignContent: "flex-start",
                    gap: "5px",
                    flexWrap: "wrap",
                    marginTop: "10px",
                  }}
                >
                  {links &&
                    links.map((link) =>
                      ReactPlayer.canPlay(link) ? (
                        <div
                          style={{
                            borderRadius: "5px",
                            width: "100px",
                            height: "80px",
                          }}
                        >
                          {" "}
                          <ReactPlayer
                            url={link}
                            width="100%"
                            height="100%"
                            playing={false}
                            muted={true}
                            controls={false}
                            className="react-player"
                          />
                        </div>
                      ) : (
                        <div
                          className="link-container"
                          onClick={() => openLinkInNewTab(link)}
                        >
                          <LinkIcon></LinkIcon>
                        </div>
                      )
                    )}
                </div>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                <Button className="cancel-btn" variant="text">
                  Cancel
                </Button>
                <Button
                  onClick={handleNewProject}
                  disabled={disableButton}
                  className="create-btn"
                  variant="contained"
                >
                  START COLLABING
                </Button>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography
                  sx={{
                    color: "#222222",
                    textAlign: "left",
                    fontWeight: "400",
                    marginBottom: "15px",
                  }}
                  variant="subtitle2"
                >
                  Media
                </Typography>
                <SortableTool sendImages={handleImages} />
              </Grid> */}
            </Grid>
            {/* <Button
              component="label"
              variant="contained"
              onClick={handleNewProject}
              disabled={disableButton}
              sx={{
                marginTop: "20px",
                width: "100%",
                marginBottom: "15px",
                backgroundColor: "#564a92",
                ":hover": {
                  bgcolor: "#564a92b8",
                },
              }}
            >
              Create Project
            </Button> */}
            {showProgress && (
              <LinearProgress sx={{ marginTop: "15px" }} color="secondary" />
            )}
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default CreateProject;
