import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useCallback, useRef, useEffect, useState } from "react";
import Home from "./components/Home";
import Login from "./components/Login";
import ProjectPage from "./components/ProjectPage";
import Chat from "./components/Chat";
import Settings from "./components/Settings";
import Profile from "./components/Profile";
import MyProjects from "./components/MyProjects";
import SignUp from "./components/SignUp";
import CreateProject from "./components/CreateProject";
import ForgotPassword from "./components/ForgotPass";
import PrivateRoute from "./components/PrivateRoute";
import Favorites from "./components/Favorites";
import Submissions from "./components/Submissions";
import ViewSubmissions from "./components/ViewSubmissions";
import EditProject from "./components/EditProject";
import { ProfileImageProvider } from "./components/ProfileImageProvider";
import { ProjectsProvider } from "./components/ProjectsProvider";
import TopAppBar from "./components/TopAppBar";
import { checkSession } from "./components/auth";

const App = () => {
  const [socketOpen, setSocketOpen] = useState(true);
  const location = useLocation(); // Get the current location
  const isLoginPage = location.pathname === "/login"; // Check if it's the login page
  const isSignUpPage = location.pathname === "/signup"; // Check if it's the signup page
  const isForgotPasswordPage = location.pathname === "/forgotpassword"; // Check if it's the forgot password page
  const webSocketRef = useRef(null);

  const initWebSocket = useCallback(() => {
    const userEmail = localStorage.getItem("userEmail");
    webSocketRef.current = new WebSocket(
      `wss://ldp5bxc5nc.execute-api.us-east-1.amazonaws.com/prod?userEmail=${userEmail}`
    );

    webSocketRef.current.onopen = (event) => {
      console.log("Connection opened!", event);
      setSocketOpen(true);
    };

    webSocketRef.current.onmessage = (event) => {
      console.log("Message received:", event.data);
    };

    webSocketRef.current.onerror = (event) => {
      console.error("WebSocket error:", event);
    };

    webSocketRef.current.onclose = (event) => {
      console.log("Connection closed:", event);
      setSocketOpen(false);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("accessToken") && !webSocketRef.current) {
      initWebSocket();
    }

    if (localStorage.getItem("accessToken")) {
      checkSession();
    }
  }, []);

  useEffect(() => {
    // Function to handle user activity and reinitialize WebSocket
    const handleUserActivity = () => {
      console.log("User activity detected, initializing WebSocket");

      // Remove event listeners immediately to prevent multiple triggers
      const events = ["mousemove", "keypress", "click"];
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );

      if (localStorage.getItem("accessToken") && socketOpen === false) {
        initWebSocket();
      }
    };

    // Add event listeners if the WebSocket is not open
    if (!socketOpen) {
      console.log("WebSocket is closed, adding event listeners");
      const events = ["mousemove", "keypress", "click"];
      events.forEach((event) =>
        window.addEventListener(event, handleUserActivity)
      );
    }

    // Cleanup function to remove event listeners
    return () => {
      console.log("Cleaning up event listeners");
      const events = ["mousemove", "keypress", "click"];
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
    };
  }, [socketOpen]);

  return (
    <ProfileImageProvider>
      <ProjectsProvider>
        <div className="App">
          {isLoginPage || isSignUpPage || isForgotPasswordPage ? null : (
            <TopAppBar wsRef={webSocketRef} />
          )}

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/project/:projectId" element={<ProjectPage />} />
            <Route
              path="/projects"
              element={
                <PrivateRoute>
                  <MyProjects />
                </PrivateRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <PrivateRoute>
                  <Chat ws={webSocketRef.current} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/favorites"
              element={
                <PrivateRoute>
                  <Favorites />
                </PrivateRoute>
              }
            />
            <Route
              path="/submissions/:projectId"
              element={
                <PrivateRoute>
                  <Submissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/submissions/role/:roleId"
              element={
                <PrivateRoute>
                  <ViewSubmissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/:userId"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/createproject"
              element={
                <PrivateRoute>
                  <CreateProject />
                </PrivateRoute>
              }
            />
            <Route
              path="/editproject/:projectId"
              element={
                <PrivateRoute>
                  <EditProject />
                </PrivateRoute>
              }
            />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </div>
      </ProjectsProvider>
    </ProfileImageProvider>
  );
};

export default App;
