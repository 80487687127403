import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { v4 as uuidv4 } from "uuid";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { checkSession } from "./auth";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import quill styles
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SortableTool from "./Sortable";
import LinearProgress from "@mui/material/LinearProgress";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import { handleUpload, deleteFromS3 } from "./imageUtils";

let Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = [
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "30px",
  "32px",
  "34px",
  "36px",
  "38px",
  "40px",
];
ReactQuill.Quill.register(Size, true);

const modules = {
  toolbar: [
    // ... (other toolbar options)
    [{ size: Size.whitelist }],
    [{ font: [] }], // Dropdown to select font
    [{ size: ["small", false, "large", "huge"] }], // Dropdown to select font size
    [{ align: [] }], // Dropdown with text align options
    ["bold", "italic", "underline", "strike"], // Toggles for bold, italic, underline, and strikethrough
    [{ color: [] }, { background: [] }], // Dropdowns to select text color and background color
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ], // Lists and indentation
    [{ direction: "rtl" }], // Text direction
  ],
};

const EditProject = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [roleOptions, setRoleOptions] = useState([]);
  const [openDialog, toggleOpen] = useState(false);
  const [openRoleDialog, toggleRoleOpen] = useState(false);
  const [projectDate, setProjectDate] = useState(null);

  const [dialogValue, setDialogValue] = useState({
    ID: "",
    RoleName: "",
  });
  const [userDialogValue, setUserDialogValue] = useState({
    ID: "",
    RoleName: "",
  });
  const [userRole, setUserRole] = useState(null);
  const [durationQuantity, setDurationQuantity] = useState("");
  const [duration, setDuration] = useState("Days");
  const [rolesArray, setRolesArray] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [description, setDescription] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectLocation, setProjectLocation] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openDuration, setOpenDuration] = useState(false);
  const [images, setImages] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [projectCategory, setProjectCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [project, setProject] = useState({});
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProject = () => {
      fetch(
        `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects?id=${projectId}`
      )
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          console.log(result);
          setProject(result);
          setProjectName(result.projectName);
          setDescription(result.projectDescription);
          setProjectDate(dayjs(result.projectData));
          setOpenDate(result.projectDateIsOpen);
          setProjectLocation(result.projectLocation);
          setOpenLocation(result.projectLocationIsOpen);
          setDurationQuantity(result.projectDurationQuantity);
          setDuration(result.projectDurationType);
          setOpenDuration(result.projectDurationIsOpen);
          //setCurrentRole(result.projectCurrentRole);
          setRolesArray(result.projectRolesArray);
          setProjectCategory(result.projectCategory);
          setUserRole(result.projectCurrentRole);
          setLoading(false);
        });
    };

    async function init() {
      await checkSession();
      refreshRoles();
      refreshCategories();
      getProject();
    }
    init();
  }, [projectId]);

  useEffect(() => {
    const checkForm = () => {
      if (
        projectName &&
        description &&
        projectDate &&
        projectLocation &&
        durationQuantity &&
        duration &&
        rolesArray &&
        projectCategory
      ) {
        setDisableButton(false);
      }
    };

    checkForm();
  }, [
    projectName,
    description,
    projectDate,
    projectLocation,
    durationQuantity,
    duration,
    rolesArray,
    projectCategory,
    project,
  ]);

  const handleImages = (data) => {
    setImages(data);
  };

  const refreshRoles = () => {
    fetch(`https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/roles`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setRoleOptions(result);
      });
  };

  const refreshCategories = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/categories`
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setCategoryOptions(JSON.parse(result.values));
      });
  };

  const handleDialogClose = () => {
    setDialogValue({
      ID: uuidv4(),
      RoleName: "",
    });
    toggleOpen(false);
  };

  const handleRoleDialogClose = () => {
    setUserDialogValue({
      ID: uuidv4(),
      RoleName: "",
    });
    toggleRoleOpen(false);
  };

  const handleNewRole = () => {
    setRolesArray((rolesArray) => [
      ...rolesArray,
      {
        id: uuidv4(),
        label: currentRole.RoleName,
      },
    ]);
  };

  const handleRoleSubmit = (event) => {
    event.preventDefault();
    setRoleOptions((roleOptions) => [
      ...roleOptions,
      {
        ID: uuidv4(),
        RoleName: dialogValue.RoleName,
      },
    ]);
    setCurrentRole(dialogValue);
    handleDialogClose();
  };

  const handleUserRoleSubmit = (event) => {
    event.preventDefault();
    setRoleOptions((roleOptions) => [
      ...roleOptions,
      {
        ID: uuidv4(),
        RoleName: userDialogValue.RoleName,
      },
    ]);
    setUserRole(userDialogValue);
    handleRoleDialogClose();
  };

  const handleDelete = (id) => {
    setRolesArray(rolesArray.filter((role) => role.id !== id));
  };

  const handleEditProject = async () => {
    setShowProgress(true);
    try {
      const mediaUrls = await handleUpload({
        images,
        project: project.id,
      }).catch(console.error);
      const now = new Date();
      const utcString = now.toISOString(); // Converts to ISO 8601 format in UTC
      const projectData = {
        projectName: projectName,
        description: description,
        projectDate: projectDate.$d,
        openDate: openDate,
        location: projectLocation,
        openLocation: openLocation,
        durationQuantity: durationQuantity,
        durationType: duration,
        openDuration: openDuration,
        currentRole: currentRole,
        rolesArray: rolesArray,
        userEmail: localStorage.getItem("userEmail"),
        id: project.id,
        media: mediaUrls,
        updatedAt: utcString,
        category: projectCategory,
        projectOwner: `${localStorage.getItem(
          "firstName"
        )} ${localStorage.getItem("lastName")}`,
      };

      try {
        const response = await fetch(
          "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects",
          {
            headers: {
              Authorization: `${localStorage.getItem("accessToken")}`,
            },
            method: "PUT",
            body: JSON.stringify(projectData),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        console.log("Create project was successful:", result);
        setShowProgress(false);
        navigate(`/project/${projectId}`);
      } catch (error) {
        console.error("Create project failed:", error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setDuration(event.target.value);
  };

  const handleAddItem = (event) => {
    event.stopPropagation();
    toggleOpen(true);
  };

  const handleUserRoleItem = (event) => {
    event.stopPropagation();
    toggleRoleOpen(true);
  };

  const CustomOption = styled("li")(({ theme }) => ({
    [`&.${autocompleteClasses.option}`]: {
      justifyContent: "left",
      backgroundColor: "#f9f9f9",
    },
  }));

  useEffect(() => {
    // Function to initialize the autocomplete
    const initializeAutocomplete = () => {
      if (!inputRef.current) return;

      // Remove the restriction on types to allow different types of places (like cities, areas)
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        inputRef.current.value = place.formatted_address || place.name; // Use place.name for areas without a formatted_address
        setProjectLocation(place.formatted_address || place.name);
      });
    };

    // Rest of your useEffect and component

    if (window.google && window.google.maps && window.google.maps.places) {
      initializeAutocomplete();
      // Set the input value after initializing Autocomplete
    } else {
      window.initMap = () => {
        initializeAutocomplete();
      };
    }

    if (inputRef.current) {
      inputRef.current.value = project.projectLocation;
    }
  }, [project.projectLocation]);

  useEffect(() => {
    if (project && inputRef.current) {
      inputRef.current.value = project.projectLocation;
    }
  }, [project, project.projectLocation]);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff", paddingBottom: "50px" }}>
      {loading ? (
        <Box
          component="main"
          sx={{
            padding: 5,
            marginTop: "10px",
            borderRadius: "10px",
            width: "60%",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            gap: "14px",
          }}
          className="custom-margins"
        >
          <Skeleton variant="rounded" width={210} height={30} />
          <Skeleton variant="rounded" width={90} />
          <Skeleton variant="rounded" height={50} />
          <Skeleton variant="rounded" width={90} />
          <Skeleton variant="rounded" height={300} />
          <Skeleton variant="rounded" />
          <Skeleton variant="rounded" height={50} />
          <Skeleton variant="rounded" />
          <Skeleton variant="rounded" height={50} />
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            padding: 5,
            marginTop: "10px",
            borderRadius: "10px",
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "left",
            gap: "10px",
          }}
          className="custom-margins"
        >
          <Typography
            sx={{
              color: "#222222",
              textAlign: "left",
              fontWeight: "400",
              marginBottom: "15px",
            }}
            variant="h5"
          >
            Create New Project
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#222222",
                  textAlign: "left",
                  fontWeight: "400",
                  marginBottom: "15px",
                }}
                variant="subtitle2"
              >
                Project Name
              </Typography>

              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Enter Project Name"
                value={projectName || ""}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#222222",
                  textAlign: "left",
                  fontWeight: "400",
                  marginBottom: "15px",
                }}
                variant="subtitle2"
              >
                Description
              </Typography>
              <ReactQuill
                theme="snow"
                modules={modules}
                value={description}
                onChange={setDescription}
              />
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  marginBottom: "15px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#222222",
                    textAlign: "left",
                    fontWeight: "400",
                  }}
                  variant="subtitle2"
                >
                  Project Date
                </Typography>
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={openDate}
                        onChange={(event) => setOpenDate(event.target.checked)}
                        size="small"
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 16 },
                          color: "#564a92",
                          "&.Mui-checked": {
                            color: "#564a92",
                          },
                        }}
                      />
                    }
                    label="Open"
                    componentsProps={{
                      typography: {
                        sx: { fontWeight: "300" },
                      },
                    }}
                  />
                  <Tooltip
                    title="Once you mark ‘Open’, you need to specify the date after your project is published"
                    placement="right"
                  >
                    <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                  </Tooltip>
                </FormGroup>
              </Box>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={projectDate}
                  onChange={(newValue) => {
                    setProjectDate(newValue);
                  }}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  marginBottom: "15px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#222222",
                    textAlign: "left",
                    fontWeight: "400",
                  }}
                  variant="subtitle2"
                >
                  Project Location
                </Typography>
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={openLocation}
                        onChange={(event) =>
                          setOpenLocation(event.target.checked)
                        }
                        size="small"
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 16 },
                          color: "#564a92",
                          "&.Mui-checked": {
                            color: "#564a92",
                          },
                        }}
                      />
                    }
                    label="Open"
                    componentsProps={{
                      typography: {
                        sx: { fontWeight: "300" },
                      },
                    }}
                  />
                  <Tooltip
                    title="Once you mark ‘Open’, you need to specify the location after your project is published"
                    placement="right"
                  >
                    <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                  </Tooltip>
                </FormGroup>
              </Box>

              <TextField
                inputRef={inputRef}
                placeholder="Address"
                variant="outlined"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  marginBottom: "15px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#222222",
                    textAlign: "left",
                    fontWeight: "400",
                  }}
                  variant="subtitle2"
                >
                  Project Duration
                </Typography>
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={openDuration}
                        onChange={(event) =>
                          setOpenDuration(event.target.checked)
                        }
                        size="small"
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 16 },
                          color: "#564a92",
                          "&.Mui-checked": {
                            color: "#564a92",
                          },
                        }}
                      />
                    }
                    label="Open"
                    componentsProps={{
                      typography: {
                        sx: { fontWeight: "300" },
                      },
                    }}
                  />
                  <Tooltip
                    title="Once you mark ‘Open’, you need to specify the duration after your project is published"
                    placement="right"
                  >
                    <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                  </Tooltip>
                </FormGroup>
              </Box>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    placeholder="3"
                    type="number"
                    sx={{ marginRight: "20px", width: "100%" }}
                    value={durationQuantity}
                    onChange={(e) => setDurationQuantity(e.target.value)}
                  />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={duration}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Hours"}>Hours</MenuItem>
                      <MenuItem value={"Days"}>Days</MenuItem>
                      <MenuItem value={"Weeks"}>Weeks</MenuItem>
                      <MenuItem value={"Months"}>Months</MenuItem>
                      <MenuItem value={"Years"}>Years</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#222222",
                  textAlign: "left",
                  fontWeight: "400",
                  marginBottom: "15px",
                }}
                variant="subtitle2"
              >
                Your Project Role
              </Typography>
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(option, value) =>
                  option.RoleName === value.RoleName
                }
                value={userRole}
                onChange={(event, newValue) => {
                  setUserRole(newValue);
                }}
                id="combo-box-demo"
                options={[
                  { RoleName: "Add new item", specialOption: true },
                  ...roleOptions,
                ]}
                getOptionLabel={(option) => option.RoleName || ""}
                sx={{ width: "100%" }}
                renderOption={(props, option) => {
                  if (option.specialOption) {
                    return (
                      <CustomOption {...props}>
                        <Button
                          sx={{ color: "#564a92" }}
                          startIcon={<AddCircleIcon />}
                          onClick={(event) => handleUserRoleItem(event)}
                        >
                          Add New Role
                        </Button>
                      </CustomOption>
                    );
                  }
                  return (
                    <Box component="li" {...props}>
                      {option.RoleName}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Choose Role" />
                )}
              />
              <Dialog open={openRoleDialog} onClose={handleRoleDialogClose}>
                <form onSubmit={handleUserRoleSubmit}>
                  <DialogTitle>Add a new role</DialogTitle>
                  <DialogContent>
                    <DialogContentText sx={{ marginBottom: "20px" }}>
                      Did we miss any? Please, add it!
                    </DialogContentText>
                    <TextField
                      autoFocus
                      id="name"
                      value={userDialogValue.RoleName}
                      onChange={(event) =>
                        setUserDialogValue({
                          ...userDialogValue,
                          RoleName: event.target.value,
                        })
                      }
                      placeholder="Enter Role"
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleRoleDialogClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#222222",
                  textAlign: "left",
                  fontWeight: "400",
                  marginBottom: "15px",
                }}
                variant="subtitle2"
              >
                Project Category
              </Typography>
              <Autocomplete
                freeSolo
                value={projectCategory}
                onChange={(event, newValue) => {
                  setProjectCategory(newValue);
                }}
                options={categoryOptions.map((option) => option)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Choose Category" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#222222",
                  textAlign: "left",
                  fontWeight: "400",
                  marginBottom: "15px",
                }}
                variant="subtitle2"
              >
                Roles to Fill
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Autocomplete
                  disablePortal
                  value={currentRole}
                  isOptionEqualToValue={(option, value) =>
                    option.RoleName === value.RoleName
                  }
                  onChange={(event, newValue) => {
                    setCurrentRole(newValue);
                  }}
                  id="combo-box-demo"
                  options={[
                    { RoleName: "Add new item", specialOption: true },
                    ...roleOptions,
                  ]}
                  getOptionLabel={(option) => option.RoleName || ""}
                  sx={{ width: "80%" }}
                  renderOption={(props, option) => {
                    if (option.specialOption) {
                      return (
                        <CustomOption {...props}>
                          <Button
                            sx={{ color: "#564a92" }}
                            startIcon={<AddCircleIcon />}
                            onClick={(event) => handleAddItem(event)}
                          >
                            Add New Role
                          </Button>
                        </CustomOption>
                      );
                    }
                    return (
                      <Box component="li" {...props}>
                        {option.RoleName}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Add New Role" />
                  )}
                />
                <Dialog open={openDialog} onClose={handleDialogClose}>
                  <form onSubmit={handleRoleSubmit}>
                    <DialogTitle>Add a new role</DialogTitle>
                    <DialogContent>
                      <DialogContentText sx={{ marginBottom: "20px" }}>
                        Did we miss any? Please, add it!
                      </DialogContentText>
                      <TextField
                        autoFocus
                        id="name"
                        value={dialogValue.RoleName}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            RoleName: event.target.value,
                          })
                        }
                        placeholder="Enter Role"
                        type="text"
                        variant="outlined"
                        fullWidth
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDialogClose}>Cancel</Button>
                      <Button type="submit">Add</Button>
                    </DialogActions>
                  </form>
                </Dialog>
                <Button
                  onClick={handleNewRole}
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#564a92",
                    ":hover": {
                      bgcolor: "#564a92b8",
                    },
                    borderRadius: "22px",
                  }}
                >
                  Add Role
                </Button>
              </Box>

              <Box sx={{ display: "flex", gap: "8px", marginTop: "15px" }}>
                {rolesArray &&
                  rolesArray.map((role) => {
                    return (
                      <Chip
                        key={role.id}
                        label={role.label}
                        onDelete={() => handleDelete(role.id)}
                        sx={{
                          backgroundColor: "#564a92",
                          color: "white",
                          "& .MuiChip-deleteIcon": {
                            color: "white",
                          },
                        }}
                      />
                    );
                  })}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#222222",
                  textAlign: "left",
                  fontWeight: "400",
                  marginBottom: "15px",
                }}
                variant="subtitle2"
              >
                Media
              </Typography>
              <SortableTool
                sendImages={handleImages}
                initialData={project.projectMedia.map(
                  (key) => `https://d71lxjj3xpfs2.cloudfront.net/${key}`
                )}
                deleteFromS3={deleteFromS3}
              />
            </Grid>
          </Grid>
          <Button
            component="label"
            variant="contained"
            onClick={handleEditProject}
            disabled={disableButton}
            sx={{
              marginTop: "20px",
              width: "100%",
              marginBottom: "15px",
              backgroundColor: "#564a92",
              ":hover": {
                bgcolor: "#564a92b8",
              },
            }}
          >
            Update Project
          </Button>
          {showProgress && (
            <LinearProgress sx={{ marginTop: "15px" }} color="secondary" />
          )}
        </Box>
      )}
    </Box>
  );
};

export default EditProject;
