import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Badge from "@mui/material/Badge";

const MessageContainer = ({
  name,
  message,
  time,
  icon,
  unread,
  participants,
  currentUser,
  onClick,
  unreadCount,
}) => {
  return (
    <Box className="messageContainer" onClick={onClick}>
      <Container
        className="noPadding"
        sx={{ width: "auto", marginRight: "20px" }}
      >
        {" "}
        <Badge color="error" badgeContent={unreadCount}>
          <AvatarGroup max={3}>
            {participants
              .filter((participant) => participant.userEmail !== currentUser)
              .map((participant) => (
                <Avatar
                  key={participant.userEmail}
                  alt={participant.userName}
                  src={participant.profileImageUrl}
                />
              ))}
          </AvatarGroup>
        </Badge>
      </Container>
      <Container
        className="noPadding"
        sx={{
          textAlign: "left",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "500",
          }}
        >
          {participants.find(
            (participant) => participant.userEmail !== currentUser
          )?.userName || "User"}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            textTransform: "none",
          }}
        >
          {message}
        </Typography>
      </Container>
      <Container
        className="noPadding"
        sx={{
          textAlign: "right",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <Typography
          variant="overline"
          gutterBottom
          sx={{
            fontWeight: "400",
            textTransform: "none",
            color: "rgba(124, 124, 124, 1)",
          }}
        >
          {time}
        </Typography>
        {unread !== 0 && (
          <Avatar
            sx={{
              bgcolor: "#d32f2f",
              width: "18px",
              height: "18px",
              fontSize: "0.8rem",
            }}
            alt="unread"
          >
            {unread}
          </Avatar>
        )}
      </Container>
    </Box>
  );
};

export default MessageContainer;
