import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import { checkSession } from "./auth";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactPlayer from "react-player";

const MyProjects = () => {
  const navigate = useNavigate();
  const [myProjects, setMyProjects] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectToDelete, setProjectToDelete] = useState("");

  const navigateToProjectPage = (id) => {
    navigate(`/project/${id}`);
  };

  const navigateToEditProjectPage = (id) => {
    navigate(`/editproject/${id}`);
  };

  const handleClose = () => {
    setOpen(false);
    setProjectName("");
    setProjectToDelete("");
  };

  const handleOpen = (projectName, projectId) => {
    setOpen(true);
    setProjectName(projectName);
    setProjectToDelete(projectId);
  };

  const navigateToSubmissionsPage = (id) => {
    navigate("/submissions/" + id);
  };

  const deleteProject = async () => {
    try {
      const response = await fetch(
        `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects?id=${projectToDelete}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Remove the deleted project from the projects array
      setMyProjects((projects) =>
        projects.filter((project) => project.id !== projectToDelete)
      );

      handleClose();
    } catch (error) {
      console.error("Delete project failed:", error);
    }
  };

  const getMyProjects = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projectsbyuser?email=${localStorage.getItem(
        "userEmail"
      )}`,
      {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setInProgress(false);
        setMyProjects(result);
      });
  };

  useEffect(() => {
    getMyProjects();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
          className="custom-margins"
        >
          <Typography
            sx={{ color: "#222222", textAlign: "left", marginBottom: "30px" }}
            variant="h5"
          >
            My Projects
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "25px",
              flexWrap: "wrap",
            }}
          >
            {myProjects &&
              myProjects.length > 0 &&
              myProjects.map((project) => {
                return (
                  <Card key={project.id} className="card-project">
                    {ReactPlayer.canPlay(project.projectMedia[0]) ? (
                      <CardMedia
                        onClick={() => navigateToProjectPage(project.id)}
                        component="div"
                      >
                        <ReactPlayer
                          url={project.projectMedia[0]}
                          width={350}
                          height={300}
                          playing={true}
                          loop={true}
                          volume={1}
                          muted={true}
                          controls={false}
                        />
                      </CardMedia>
                    ) : (
                      <CardMedia
                        onClick={() => navigateToProjectPage(project.id)}
                        component="img"
                        className="card-media"
                        image={`https://d71lxjj3xpfs2.cloudfront.net/${project.projectMedia[0]}`}
                        title="photoshoot"
                      />
                    )}

                    <CardContent sx={{ padding: "10px 8px 0px 8px" }}>
                      <Typography variant="h6" component="div">
                        {project.projectName}
                      </Typography>
                      <Container
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          mr={1}
                          variant="caption"
                          color="text.secondary"
                        >
                          By: {project.projectOwner}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "700" }}
                        >
                          {" "}
                          {project.projectCurrentRole.RoleName}
                        </Typography>
                      </Container>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        paddingBottom: "10px",
                      }}
                    >
                      <Button
                        sx={{ borderRadius: "14px" }}
                        size="small"
                        variant="outlined"
                        onClick={() => navigateToEditProjectPage(project.id)}
                      >
                        Edit
                      </Button>
                      <Button
                        sx={{ borderRadius: "14px" }}
                        size="small"
                        variant="outlined"
                        onClick={() => navigateToSubmissionsPage(project.id)}
                      >
                        Manage Team
                      </Button>
                      <Button
                        sx={{ borderRadius: "14px" }}
                        size="small"
                        variant="outlined"
                        onClick={() =>
                          handleOpen(project.projectName, project.id)
                        }
                        color="error"
                      >
                        Remove
                      </Button>
                    </CardActions>
                  </Card>
                );
              })}
            <Dialog
              onClose={handleClose}
              open={open}
              PaperProps={{
                style: { width: "50%" }, // Custom width
              }}
            >
              <DialogTitle>Remove Project</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please confirm if you want to remove {projectName} from your
                  projects
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deleteProject}>Confirm</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
            {!inProgress && myProjects.length === 0 && (
              <Typography variant="subtitle1">No projects to show</Typography>
            )}
            {inProgress &&
              Array.from({ length: 8 }).map((_, index) => (
                // Your JSX here. Use `index` if you need the iteration count.
                <Card key={index} className="card-project">
                  <CardMedia className="card-media" title="photoshoot">
                    {" "}
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                    ></Skeleton>
                  </CardMedia>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "10px 8px 0px 8px",
                      height: "110px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Skeleton variant="text" width="80%"></Skeleton>
                    <Skeleton variant="text" width="80%"></Skeleton>
                  </Box>
                </Card>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyProjects;
