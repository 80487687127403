import React, { createContext, useState, useContext } from "react";

const ProfileImageContext = createContext();

export const useProfileImage = () => useContext(ProfileImageContext);

export const ProfileImageProvider = ({ children }) => {
  const [profileImageUrl, setProfileImageUrl] = useState(
    localStorage.getItem("profileImageUrl") || ""
  );

  const updateProfileImageUrl = (url) => {
    setProfileImageUrl(url);
    localStorage.setItem("profileImageUrl", url);
  };

  return (
    <ProfileImageContext.Provider
      value={{ profileImageUrl, updateProfileImageUrl }}
    >
      {children}
    </ProfileImageContext.Provider>
  );
};
