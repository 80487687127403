import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import PostAddIcon from "@mui/icons-material/PostAdd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";

const Submissions = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [project, setProject] = useState("");
  const [team, setTeam] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [showCallSheetButton, setShowCallSheetButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [memo, setMemo] = useState("");
  const [editCallSheet, setEditCallSheet] = useState(false);

  const handleMemo = (e) => {
    setMemo(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditCallSheet(false);
  };

  const saveCallSheet = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects/callsheet`,
      {
        method: "PUT",
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          memo,
          team,
          projectId,
        }),
      }
    )
      .then((res) => {})
      .then((result) => {
        setEditCallSheet(false);
        setMemo("");
      });
  };

  const handleTimeChange = (memberId, type, newValue) => {
    setTeam((prevTeam) =>
      prevTeam.map((member) =>
        member.id === memberId ? { ...member, [type]: newValue } : member
      )
    );
  };

  const calculateDuration = (duration, durationType) => {
    if (durationType === "Hours") {
      if (duration === "1") {
        return `${duration} Hour`;
      } else {
        return `${duration} Hours`;
      }
    } else if (durationType === "Days") {
      if (duration === "1") {
        return `${duration} Day`;
      } else {
        return `${duration} Days`;
      }
    } else if (durationType === "Weeks") {
      if (duration === "1") {
        return `${duration} Week`;
      } else {
        return `${duration} Weeks`;
      }
    } else if (durationType === "Months") {
      if (duration === "1") {
        return `${duration} Month`;
      } else {
        return `${duration} Months`;
      }
    }
  };

  const navigateToViewSubmissionsPage = (id, applications, name) => {
    navigate("/submissions/role/" + id, {
      state: { applications, name, id, projectId },
    });
  };

  const getProject = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects/team?id=${projectId}`
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log("Hi:", result);
        setProject(result.project);
        setTeam(result.projectTeam);
        {
          result.project.callSheetMemo && setMemo(result.project.callSheetMemo);
        }
      });
  };

  const getSubmissions = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/submissions?id=${projectId}`,
      {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setSubmissions(result.groupedResults);
        if (result.allRolesFilled) {
          setShowCallSheetButton(true);
        }
      });
  };

  useEffect(() => {
    getProject();
    getSubmissions();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {project ? (
        <Box
          className="custom-margins"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "3rem",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "left", fontWeight: "300" }}
              >
                {project.projectName}
              </Typography>

              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  padding: "0 !important",
                  margin: "0px !important",
                }}
              >
                <Typography mr={2} variant="body2" color="text.secondary">
                  By: {project.projectOwner}
                </Typography>

                <Typography mr={2} variant="body2" sx={{ fontWeight: "700" }}>
                  {" "}
                  {project.projectCurrentRole &&
                    project.projectCurrentRole.RoleName}
                </Typography>
                <Chip
                  sx={{ backgroundColor: "#ffffff", marginRight: "2px" }}
                  icon={<GroupsIcon />}
                  label={
                    project.projectRolesArray &&
                    `${project.projectRolesArray.length} people`
                  }
                />
                <Chip
                  sx={{ backgroundColor: "#ffffff" }}
                  icon={<TimelapseIcon />}
                  label={calculateDuration(
                    project.projectDurationQuantity,
                    project.projectDurationType
                  )}
                />
              </Container>
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <Container
                sx={{
                  padding: "30px !important",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                  height: "100%",
                  minHeight: "350px",
                  margin: "0px !important",
                  maxWidth: "1280px !important",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ textAlign: "left", fontWeight: "400" }}
                  >
                    Team
                  </Typography>
                  {showCallSheetButton && (
                    <Tooltip title="Call Sheet">
                      <IconButton onClick={handleClickOpen}>
                        <PostAddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"lg"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                      alignItems: "center",
                    }}
                  >
                    <DialogTitle>Call Sheet</DialogTitle>
                    {!editCallSheet && (
                      <IconButton onClick={() => setEditCallSheet(true)}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </Box>
                  <DialogContent sx={{ paddingTop: "0px !important" }}>
                    <Typography
                      variant="button"
                      sx={{
                        fontWeight: "400",
                        textTransform: "none",
                      }}
                    >
                      Project Date:{" "}
                      {dayjs(project.projectDate).format("M/D/YY")}
                    </Typography>
                    <Box>
                      <Typography sx={{ marginTop: "5px" }} variant="overline">
                        {project.projectLocation}
                      </Typography>
                    </Box>

                    {team &&
                      team.map((item) => (
                        <Container
                          key={item.id}
                          sx={{
                            padding: "0px !important",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px",
                            gap: "30px",
                          }}
                        >
                          {project.callSheet && !editCallSheet ? (
                            <React.Fragment>
                              <Typography
                                variant="caption"
                                sx={{
                                  fontWeight: "400",
                                  textTransform: "none",
                                }}
                              >
                                Call Time:{" "}
                              </Typography>
                              <Typography
                                variant="button"
                                sx={{
                                  fontWeight: "500",
                                  textTransform: "none",
                                }}
                              >
                                {dayjs(item.callTime).format("h:mm a")}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  fontWeight: "400",
                                  textTransform: "none",
                                }}
                              >
                                Wrap Time:{" "}
                              </Typography>
                              <Typography
                                variant="button"
                                sx={{
                                  fontWeight: "500",
                                  textTransform: "none",
                                }}
                              >
                                {dayjs(item.wrapTime).format("h:mm a")}
                              </Typography>
                            </React.Fragment>
                          ) : (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label="Call Time"
                                value={item.callTime || null}
                                onChange={(newValue) =>
                                  handleTimeChange(
                                    item.id,
                                    "callTime",
                                    newValue
                                  )
                                }
                              />
                              <TimePicker
                                label="Wrap Time"
                                value={item.wrapTime || null}
                                onChange={(newValue) =>
                                  handleTimeChange(
                                    item.id,
                                    "wrapTime",
                                    newValue
                                  )
                                }
                              />
                            </LocalizationProvider>
                          )}
                          <Avatar alt={item.name} src={item.profileImageUrl} />{" "}
                          <Typography
                            variant="overline"
                            sx={{ textAlign: "left" }}
                          >
                            {item.name}
                          </Typography>
                          <Chip label={item.role} />
                        </Container>
                      ))}

                    <Typography
                      sx={{ marginTop: "20px", marginBottom: "10px" }}
                      variant="body2"
                    >
                      Memo
                    </Typography>
                    {project.callSheet && !editCallSheet ? (
                      <Typography>{project.callSheetMemo}</Typography>
                    ) : (
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        sx={{ marginTop: "20px" }}
                        value={memo}
                        onChange={handleMemo}
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    {(editCallSheet || !project.callSheet) && (
                      <Button onClick={saveCallSheet} autoFocus>
                        Save
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>

                {team &&
                  team.map((item) => (
                    <Container
                      key={item.id + item.role}
                      sx={{
                        padding: "0px !important",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Avatar alt={item.name} src={item.profileImageUrl} />{" "}
                      <Typography
                        variant="overline"
                        sx={{ textAlign: "left", marginLeft: "10px" }}
                      >
                        {item.name}
                      </Typography>
                      <Chip sx={{ marginLeft: "10px" }} label={item.role} />
                    </Container>
                  ))}
                <Divider sx={{ marginTop: "20px" }} variant="middle" />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    marginTop: "20px",
                  }}
                >
                  Open Roles
                </Typography>
                {submissions &&
                  submissions
                    .filter((item) => item.filled === false) // Filter items where filled is false
                    .map((item) => (
                      <Container
                        key={item.roleId}
                        sx={{
                          padding: "0px !important",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                          gap: "20px",
                        }}
                      >
                        <Avatar>
                          <PersonIcon />
                        </Avatar>{" "}
                        <Chip label={item.roleName} />
                        <Typography variant="overline">
                          {item.count} applicants
                        </Typography>
                        {item.items && item.items.length > 0 && (
                          <Button
                            onClick={() =>
                              navigateToViewSubmissionsPage(
                                item.roleId,
                                item.items,
                                item.roleName
                              )
                            }
                            sx={{ color: "#564a92" }}
                          >
                            View Submissions
                          </Button>
                        )}
                      </Container>
                    ))}
                <Divider sx={{ marginTop: "20px" }} variant="middle" />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    marginTop: "20px",
                  }}
                >
                  Filled Roles
                </Typography>
                {submissions &&
                  submissions
                    .filter((item) => item.filled === true) // Filter items where filled is true
                    .map((item) => (
                      <Container
                        key={item.roleId}
                        sx={{
                          padding: "0px !important",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                          gap: "20px",
                        }}
                      >
                        <Avatar>
                          <PersonIcon />
                        </Avatar>{" "}
                        <Chip label={item.roleName} />
                        <Typography variant="overline">
                          {item.count} applicants
                        </Typography>
                        {item.items && item.items.length > 0 && (
                          <Button
                            onClick={() =>
                              navigateToViewSubmissionsPage(
                                item.roleId,
                                item.items,
                                item.roleName
                              )
                            }
                            sx={{ color: "#564a92" }}
                          >
                            View Submissions
                          </Button>
                        )}
                      </Container>
                    ))}
              </Container>
            </Box>
          </Box>
        </Box>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Skeleton variant="rounded" width={300} height={70} />
          <Skeleton variant="rounded" width={600} height={40} />

          <Skeleton variant="rounded" width={1280} height={300} />
        </Stack>
      )}
    </Box>
  );
};

export default Submissions;
