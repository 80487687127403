import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import ReactPlayer from "react-player";

const Favorites = () => {
  const navigate = useNavigate();
  const [myFavorites, setMyFavorites] = useState([]);
  const [inProgress, setInProgress] = useState(true);

  const navigateToProjectPage = (id) => {
    navigate(`/project/${id}`);
  };

  const calculateDuration = (duration, durationType) => {
    if (durationType === "Hours") {
      if (duration === "1") {
        return `${duration} Hour`;
      } else {
        return `${duration} Hours`;
      }
    } else if (durationType === "Days") {
      if (duration === "1") {
        return `${duration} Day`;
      } else {
        return `${duration} Days`;
      }
    } else if (durationType === "Weeks") {
      if (duration === "1") {
        return `${duration} Week`;
      } else {
        return `${duration} Weeks`;
      }
    } else if (durationType === "Months") {
      if (duration === "1") {
        return `${duration} Month`;
      } else {
        return `${duration} Months`;
      }
    }
  };

  const getMyFavorites = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/favorites?email=${localStorage.getItem(
        "userEmail"
      )}`,
      {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setInProgress(false);
        setMyFavorites(result);
      });
  };

  useEffect(() => {
    getMyFavorites();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
          className="custom-margins"
        >
          <Typography
            sx={{ color: "#222222", textAlign: "left", marginBottom: "30px" }}
            variant="h5"
          >
            Favorites
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              flexWrap: "wrap",
            }}
          >
            {myFavorites &&
              myFavorites.length > 0 &&
              myFavorites.map((project) => {
                return (
                  <Card key={project.id} className="card-project">
                    {ReactPlayer.canPlay(project.projectMedia[0]) ? (
                      <CardMedia
                        component="div"
                        onClick={() => navigateToProjectPage(project.id)}
                      >
                        <ReactPlayer
                          url={project.projectMedia[0]}
                          width={300}
                          height={300}
                          playing={true}
                          loop={true}
                          volume={1}
                          muted={true}
                          controls={false}
                        />
                      </CardMedia>
                    ) : (
                      <CardMedia
                        component="img"
                        className="card-media"
                        onClick={() => navigateToProjectPage(project.id)}
                        image={`https://d71lxjj3xpfs2.cloudfront.net/${project.projectMedia[0]}`}
                        title="photoshoot"
                      />
                    )}
                    <CardContent sx={{ padding: "10px 8px 0px 8px" }}>
                      <Typography variant="h6" component="div">
                        {project.projectName}
                      </Typography>
                      <Container
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          mr={1}
                          variant="caption"
                          color="text.secondary"
                        >
                          By: {project.projectOwner}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "700" }}
                        >
                          {" "}
                          {project.projectCurrentRole.RoleName}
                        </Typography>
                      </Container>
                    </CardContent>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <Chip
                        sx={{ backgroundColor: "#ffffff" }}
                        icon={<GroupsIcon />}
                        label={
                          project.projectRolesArray &&
                          `${project.projectRolesArray.length} people`
                        }
                      />
                      <Chip
                        sx={{ backgroundColor: "#ffffff" }}
                        icon={<TimelapseIcon />}
                        label={calculateDuration(
                          project.projectDurationQuantity,
                          project.projectDurationType
                        )}
                      />

                      <Button
                        sx={{ borderRadius: "14px" }}
                        size="small"
                        variant="outlined"
                        onClick={() => navigateToProjectPage(project.id)}
                      >
                        View
                      </Button>
                    </CardActions>
                  </Card>
                );
              })}
            {!inProgress && myFavorites.length === 0 && (
              <Typography variant="subtitle1">No favorites to show</Typography>
            )}
            {inProgress &&
              Array.from({ length: 6 }).map((_, index) => (
                // Your JSX here. Use `index` if you need the iteration count.
                <Card key={index} className="card-project">
                  <CardMedia className="card-media" title="photoshoot">
                    {" "}
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                    ></Skeleton>
                  </CardMedia>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "10px 8px 0px 8px",
                      height: "110px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Skeleton variant="text" width="80%"></Skeleton>
                    <Skeleton variant="text" width="80%"></Skeleton>
                  </Box>
                </Card>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Favorites;
