import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Lightbox from "yet-another-react-lightbox";
import Button from "@mui/material/Button";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import SortableTool from "./Sortable";
import { handleUpload, deleteFromS3 } from "./imageUtils";

const cdn = "https://d71lxjj3xpfs2.cloudfront.net/";

const Profile = () => {
  const { userId } = useParams();
  const [value, setValue] = useState(0);
  const [index, setIndex] = useState(-1);
  const [showOptions, setShowOptions] = useState(true);
  const [user, setUser] = useState({});
  const [images, setImages] = useState([]);
  const [lightBox, setLightBox] = useState([]);
  const [following, setFollowing] = useState(false);
  const [open, setOpen] = useState(false);
  const [portfolioImages, setPortfolioImages] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleImageClick = (index) => {
    setIndex(index);
  };

  const transformArrayLightBox = (array) => {
    return array.map((item, index) => ({
      src: cdn + item,
    }));
  };

  const transformArrayImageList = (array) => {
    return array.map((item, index) => ({
      img: cdn + item,
    }));
  };

  const handleImages = (data) => {
    setPortfolioImages(data);
  };

  const handleEditPortfolio = async () => {
    try {
      const mediaUrls = await handleUpload({
        images: portfolioImages,
        profile: localStorage.getItem("userId"),
      }).catch(console.error);
      const userData = {
        email: localStorage.getItem("userEmail"),
        portfolioMedia: mediaUrls,
      };

      try {
        const response = await fetch(
          "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/users/portfolio",
          {
            headers: {
              Authorization: `${localStorage.getItem("accessToken")}`,
            },
            method: "PUT",
            body: JSON.stringify(userData),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        console.log("Update portfolio was successful:", result);
        setOpen(false);
      } catch (error) {
        console.error("Update failed:", error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = () => {
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/users?id=${userId}`,
      {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        checkUser();
        setUser(result[0]);
        if (result[0].profileMedia) {
          setImages(transformArrayImageList(result[0].profileMedia));
          setLightBox(transformArrayLightBox(result[0].profileMedia));
        }
      });
  };

  const checkUser = () => {
    let currentUserId = localStorage.getItem("userId");
    if (currentUserId === userId) {
      setShowOptions(false);
    } else {
      const followUserId = user.userId;

      // Retrieve the 'following' map from localStorage
      const followingString = localStorage.getItem("following");
      const following = followingString ? JSON.parse(followingString) : {};

      // Check if the current user is following the other user
      const isFollowing = following.hasOwnProperty(followUserId);

      if (isFollowing) {
        setFollowing(true);
      } else {
        setFollowing(false);
      }
    }
  };

  const handleUnfollow = async () => {
    const userEmail = localStorage.getItem("userEmail");
    const unfollowUserId = user.userId;

    // Retrieve the 'following' map from localStorage
    let following = JSON.parse(localStorage.getItem("following")) || {};

    const data = {
      email: userEmail,
      unfollowUserId: unfollowUserId,
    };

    try {
      const response = await fetch(
        "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/followers",
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "PUT", // Make sure the API endpoint supports PUT for unfollow action
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      // Remove the unfollowed user from the 'following' map
      delete following[unfollowUserId];

      localStorage.setItem("following", JSON.stringify(following));

      console.log("Unfollow was successful:", result);
      setFollowing(false); // Assuming this updates the state to reflect the unfollow action
    } catch (error) {
      console.error("Unfollow failed:", error);
    }
  };

  const handleFollow = async () => {
    setFollowing(true);
    const userEmail = localStorage.getItem("userEmail");
    const followUserEmail = user.userEmail;
    const followUserId = user.userId; // Assuming this info is available
    const followUserName = user.userName; // Assuming this info is available
    const followUserProfileImageUrl = user.profileImageUrl
      ? user.profileImageUrl
      : null; // Assuming this info is available

    // Step 1: Retrieve the 'following' map from localStorage
    const following = JSON.parse(localStorage.getItem("following")) || {};

    // Check if the user is already being followed
    if (following[followUserEmail]) {
      console.log("Already following this user.");
      return;
    }

    const data = {
      email: userEmail,
      followUserEmail,
      followUserName,
      followUserProfileImageUrl,
      followUserId,
    };

    try {
      const response = await fetch(
        "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/followers",
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      following[followUserId] = {
        userEmail: followUserEmail,
        userName: followUserName,
        profileImageUrl: followUserProfileImageUrl,
        userId: followUserId,
      };
      localStorage.setItem("following", JSON.stringify(following));
    } catch (error) {
      console.error("Follow failed:", error);
      setFollowing(false);
    }
  };

  useEffect(() => {
    getUser();
  }, [userId]);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Container
          sx={{
            padding: "20px 0px 20px 0px !important",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "10px",
            height: "auto",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "400px",
            backgroundColor: "#ffffff",
          }}
        >
          <Avatar
            sx={{ width: 150, height: 150 }}
            alt={user.userName}
            src={user.profileImageUrl ? cdn + user.profileImageUrl : null}
          />
          <Typography
            variant="h5"
            sx={{
              marginTop: "20px",
              fontWeight: 300,
            }}
          >
            {user.userName}
          </Typography>
          {user.role && (
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: "20px",
                fontWeight: 300,
              }}
            >
              {user.role}
            </Typography>
          )}

          {user.location && (
            <Typography
              variant="caption"
              sx={{
                marginTop: "10px",
                fontWeight: 300,
              }}
            >
              Los Angeles
            </Typography>
          )}

          {showOptions && (
            <Box sx={{ display: "flex", gap: "20px" }}>
              {following ? (
                <Button
                  onClick={handleUnfollow}
                  sx={{
                    backgroundColor: "#564a92",
                    ":hover": {
                      bgcolor: "#564a92b8",
                    },
                    borderRadius: "22px",
                    marginTop: "20px",
                  }}
                  variant="contained"
                >
                  Following
                </Button>
              ) : (
                <Button
                  onClick={handleFollow}
                  sx={{
                    backgroundColor: "#564a92",
                    ":hover": {
                      bgcolor: "#564a92b8",
                    },
                    borderRadius: "22px",
                    marginTop: "20px",
                  }}
                  variant="contained"
                  startIcon={<PersonAddAltIcon />}
                >
                  Follow
                </Button>
              )}

              <Button
                sx={{
                  backgroundColor: "#564a92",
                  ":hover": {
                    bgcolor: "#564a92b8",
                  },
                  borderRadius: "22px",
                  marginTop: "20px",
                }}
                variant="contained"
                startIcon={<MapsUgcIcon />}
              >
                Message
              </Button>
            </Box>
          )}
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Edit Portfolio</DialogTitle>
            <DialogContent>
              <DialogContentText>Media</DialogContentText>
              <SortableTool
                sendImages={handleImages}
                initialData={user.profileMedia}
                deleteFromS3={deleteFromS3}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleEditPortfolio}>Apply</Button>
            </DialogActions>
          </Dialog>
        </Container>
        <Container sx={{ paddingBottom: "40px", marginTop: "60px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "left",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              Portfolio
            </Typography>
            {!showOptions && (
              <Button
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: "#564a92",
                  ":hover": {
                    bgcolor: "#564a92b8",
                  },
                  borderRadius: "22px",
                }}
                variant="contained"
                startIcon={<EditIcon />}
              >
                Edit Portfolio
              </Button>
            )}
          </Box>

          <ImageList variant="masonry" cols={3} gap={4}>
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <ImageListItem
                  key={index}
                  onClick={() => handleImageClick(index)}
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.img}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
          </ImageList>

          {lightBox && lightBox.length > 0 && (
            <Lightbox
              slides={lightBox}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              // enable optional lightbox plugins
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Profile;
