import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useProfileImage } from "./ProfileImageProvider";
import TextField from "@mui/material/TextField";
import { handleUpload, resizeAndCompressImage } from "./imageUtils";

const cdn = "https://d71lxjj3xpfs2.cloudfront.net/";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Settings = () => {
  const [value, setValue] = useState(0);
  const [user, setUser] = useState({});
  const [data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { profileImageUrl, updateProfileImageUrl } = useProfileImage();
  const [avatarSrc, setAvatarSrc] = useState(
    cdn + localStorage.getItem("profileImageUrl") || ""
  );
  const [city, setCity] = useState("");
  const [userName, setUserName] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getUser = () => {
      fetch(
        `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/users/byemail?email=${localStorage.getItem(
          "userEmail"
        )}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          console.log(result);
          setUser(result);
          if (result.profileImageUrl !== "none") {
            setAvatarSrc(cdn + result.profileImageUrl);
            updateProfileImageUrl(result.profileImageUrl);
          }
          setUserName(result.userName);
          if (result.city) {
            setCity(result.city);
          }
        });
    };

    getUser();
  }, []);

  const cancelUpload = () => {
    setAvatarSrc(cdn + user.profileImageUrl);
    setSelectedFile(null);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // Handle file selection
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const resizedBlob = await resizeAndCompressImage(
      file,
      2048, // Width
      2048, // Height
      0.9, // Quality (slightly reduced for better file size management)
      true
    ); // maxWidth, maxHeight, quality
    const localUrl = URL.createObjectURL(resizedBlob);
    setAvatarSrc(localUrl);
    const imageObject = {
      content: URL.createObjectURL(file), // This is the file content
      id: file.name, // Unique identifier for the file
    };

    // Update your state with this new object
    setSelectedFile(imageObject);
  };

  const handleImageUpdate = async () => {
    const newImageUrl = await handleUpload({
      images: [selectedFile],
      profileImage: localStorage.getItem("userId"),
    });

    setSelectedFile(null);

    const updateData = {
      email: localStorage.getItem("userEmail"),
      imageUrl: newImageUrl[0],
    };
    try {
      const response = await fetch(
        "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/users/profileimage",
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "PUT",
          body: JSON.stringify(updateData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSelectedFile(null);
      updateProfileImageUrl(newImageUrl[0]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Container
              sx={{
                padding: "20px 0px 20px 0px !important",
                marginTop: "30px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
                height: "540px",
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab label="General" {...a11yProps(0)} />
                {/* <Tab label="Billing and Payments" {...a11yProps(1)} />
                <Tab label="Password and Security" {...a11yProps(2)} />
                <Tab label="Legal" {...a11yProps(3)} /> */}
              </Tabs>
            </Container>
          </Grid>
          <Grid item xs={9}>
            <Container
              sx={{
                padding: "30px !important",
                marginTop: "30px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
                textAlign: "center",
                height: "540px",
                position: "relative",
              }}
            >
              <TabPanel value={value} index={0}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Avatar
                    sx={{ width: "200px", height: "200px" }}
                    alt={user.userName}
                    src={avatarSrc}
                  />

                  {selectedFile ? (
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Button
                        sx={{
                          backgroundColor: "#564a92",
                          ":hover": {
                            bgcolor: "#564a92b8",
                          },
                        }}
                        size="small"
                        variant="contained"
                        onClick={handleImageUpdate}
                      >
                        Save
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: "#564a92",
                          ":hover": {
                            bgcolor: "#564a92b8",
                          },
                        }}
                        size="small"
                        variant="contained"
                        onClick={cancelUpload}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      component="label"
                      sx={{ width: "140px", color: "#564a92" }}
                      variant="text"
                    >
                      Upload Photo
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                    </Button>
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={5} sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          color: "#222222",
                          fontWeight: "300",
                        }}
                        variant="h6"
                      >
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sx={{ textAlign: "left" }}>
                      {" "}
                      <TextField
                        sx={{ width: "250px" }}
                        size="small"
                        variant="outlined"
                        value={userName || ""}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5} sx={{ textAlign: "right" }}>
                      {" "}
                      <Typography
                        sx={{
                          color: "#222222",
                          fontWeight: "300",
                        }}
                        variant="h6"
                      >
                        City
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sx={{ textAlign: "left" }}>
                      {" "}
                      <TextField
                        sx={{ width: "250px" }}
                        size="small"
                        variant="outlined"
                        placeholder="Enter City"
                        value={city || ""}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              {/* <TabPanel value={value} index={1}>
                Item Two
              </TabPanel>
              <TabPanel value={value} index={2}>
                Item Three
              </TabPanel>
              <TabPanel value={value} index={3}>
                Item Four
              </TabPanel> */}
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Settings;
