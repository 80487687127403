import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ProjectRoles from "./ProjectRoles";
import Skeleton from "@mui/material/Skeleton";
import ReactPlayer from "react-player";
import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
// import TuneIcon from "@mui/icons-material/Tune";
// import MenuItem from "@mui/material/MenuItem";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import CloseIcon from "@mui/icons-material/Close";
// import Divider from "@mui/material/Divider";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Grid from "@mui/material/Grid";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import "react-multi-carousel/lib/styles.css";
// import AppsIcon from "@mui/icons-material/Apps";

function stringAvatar(name) {
  const parts = name.split(" ");
  const firstInitial = parts[0][0];
  const secondInitial = parts.length > 1 ? parts[1][0] : ""; // Check if there is a second part, use it if so, or default to an empty string

  return {
    sx: {
      bgcolor: "#fff",
      color: "#000",
    },
    children: `${firstInitial} ${secondInitial}`.trim(), // Trim to remove any trailing space if there's no second initial
  };
}

const Home = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("collaborate");
  const colors = ["#333333", "#F28EAC", "#79B4FA", "#9F7FE3"];
  let lastColorIndex = -1;

  // Function to get the next color index
  const getNextColorIndex = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * colors.length);
    } while (nextIndex === lastColorIndex);
    lastColorIndex = nextIndex;
    return nextIndex;
  };

  // const [open, setOpen] = useState(false);
  // const [location, setLocation] = useState("");

  const navigateToProjectPage = (id) => {
    addViews(id);
    navigate(`/project/${id}`);
  };

  const navigateToCreateProjectPage = () => {
    navigate("/createproject");
  };

  // const handleChange = (event) => {
  //   setLocation(event.target.value);
  // };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const addViews = async (id) => {
    try {
      const response = await fetch(
        `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects/views?id=${id}`,
        {
          method: "PUT",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Increment view failed:", error);
    }
  };

  const getMostRecentProjects = () => {
    setLoading(true);
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects/mostrecent`
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setLoading(false);
        setProjects(result);
      });
  };

  const getMostPopularProjects = () => {
    setLoading(true);
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects/mostpopular`
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setLoading(false);
        setProjects(result);
      });
  };

  useEffect(() => {
    getMostPopularProjects();
  }, []);

  const getProjectsByCategory = (category) => {
    setLoading(true);
    fetch(
      `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projectsbycategory?name=${category}`
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setLoading(false);
        setProjects(result);
      });
  };

  const calculateDuration = (duration, durationType) => {
    if (durationType === "Hours") {
      if (duration === "1") {
        return `${duration} Hour`;
      } else {
        return `${duration} Hours`;
      }
    } else if (durationType === "Days") {
      if (duration === "1") {
        return `${duration} Day`;
      } else {
        return `${duration} Days`;
      }
    } else if (durationType === "Weeks") {
      if (duration === "1") {
        return `${duration} Week`;
      } else {
        return `${duration} Weeks`;
      }
    } else if (durationType === "Months") {
      if (duration === "1") {
        return `${duration} Month`;
      } else {
        return `${duration} Months`;
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "3rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            paddingRight: "70px",
            marginBottom: "1px",
          }}
        >
          <div
            className="box"
            style={{
              zIndex: activeTab === "collaborate" ? "2" : "1",
              cursor: "pointer",
            }}
            onClick={() => setActiveTab("collaborate")}
          >
            <div className="rectangle">
              <div className="text-wrapper">Collaborate</div>
            </div>
            <div
              style={{
                display: activeTab === "collaborate" ? "block" : "none",
                width: "123px",
                height: "7px",
                backgroundColor: "white",
              }}
            ></div>
          </div>

          <div
            className="box"
            style={{
              zIndex: activeTab === "featured" ? "2" : "1",
              cursor: "pointer",
            }}
            onClick={() => {
              setActiveTab("featured");
              getMostPopularProjects();
            }}
          >
            <div className="rectangle">
              <div className="text-wrapper">Featured</div>
            </div>
            <div
              style={{
                display: activeTab === "featured" ? "block" : "none",
                width: "123px",
                height: "7px",
                backgroundColor: "white",
              }}
            ></div>
          </div>
          <div
            className="box"
            style={{
              zIndex: activeTab === "recent" ? "2" : "0",
              cursor: "pointer",
            }}
            onClick={() => {
              setActiveTab("recent");
              getMostRecentProjects();
            }}
          >
            <div className="rectangle">
              <div className="text-wrapper">Recent</div>
            </div>
            <div
              style={{
                display: activeTab === "recent" ? "block" : "none",
                width: "123px",
                height: "7px",
                backgroundColor: "white",
              }}
            ></div>
          </div>
        </Box>
        <Box
          className="divider-shadow"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            component="main"
            sx={{
              paddingTop: "20px",
              paddingBottom: "50px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "1280px !important",
              justifyContent: "space-between",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Button
              onClick={navigateToCreateProjectPage}
              variant="outlined"
              className="create-project-btn"
              endIcon={
                <img
                  alt="add icon"
                  src="/images/add_icon.svg"
                  style={{ marginLeft: "10px" }}
                ></img>
              }
            >
              Create Project
            </Button>
            <Button
              className="button-flex"
              onClick={() => getProjectsByCategory("Fashion")}
            >
              Fashion
            </Button>
            <Button
              className="button-flex"
              onClick={() => getProjectsByCategory("Music")}
            >
              {" "}
              Music
            </Button>
            <Button
              className="button-flex"
              onClick={() => getProjectsByCategory("Film")}
            >
              {" "}
              Film
            </Button>
            <Button
              className="button-flex"
              onClick={() => getProjectsByCategory("Graphic Art")}
            >
              {" "}
              Graphics
            </Button>
            <Button
              className="button-flex"
              onClick={() => getProjectsByCategory("Photography")}
            >
              {" "}
              Photography
            </Button>
            <Button
              className="button-flex"
              onClick={() => getProjectsByCategory("Illustration")}
            >
              Illustration
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
            className="custom-margins"
          >
            {!loading && projects.length === 0 && (
              <Typography variant="subtitle1">No projects to show</Typography>
            )}
            <Box sx={{ display: "flex", gap: "25px", flexWrap: "wrap" }}>
              {loading &&
                Array.from({ length: 4 }).map((_, index) => (
                  // Your JSX here. Use `index` if you need the iteration count.
                  <Card key={index} className="card-project">
                    <CardMedia className="card-media" title="photoshoot">
                      {" "}
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height="100%"
                      ></Skeleton>
                    </CardMedia>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "10px 8px 0px 8px",
                        height: "110px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Skeleton variant="text" width="80%"></Skeleton>
                      <Skeleton variant="text" width="80%"></Skeleton>
                    </Box>
                  </Card>
                ))}
              {projects &&
                projects.length > 0 &&
                projects.map((project) => {
                  return (
                    <Card key={project.id} className="card-project">
                      {project.projectMedia.length > 0 &&
                        ReactPlayer.canPlay(project.projectMedia[0]) && (
                          <CardMedia
                            onClick={() => navigateToProjectPage(project.id)}
                            className="card-media"
                          >
                            <div
                              style={{ position: "relative", height: "238px" }}
                            >
                              <ReactPlayer
                                url={project.projectMedia[0]}
                                width="300px"
                                height="288px"
                                playing={true}
                                loop={true}
                                volume={1}
                                muted={true}
                                controls={false}
                                className="react-player"
                              />
                              <div
                                className="gradient-overlay"
                                style={{
                                  position: "absolute",
                                  height: "288px",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  background:
                                    "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)",
                                }}
                              />

                              <div
                                style={{
                                  position: "relative",
                                  bottom: "288px",
                                }}
                              >
                                <div className="title">
                                  {project.projectName}
                                </div>
                                {project.projectRolesArray && (
                                  <ProjectRoles
                                    roles={project.projectRolesArray}
                                  />
                                )}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                marginLeft: "20px",
                                marginBottom: "10px",
                                zIndex: "1",
                              }}
                            >
                              <Avatar
                                className="avatar"
                                {...stringAvatar(project.projectOwner)}
                              />
                              <div className="owner">
                                {project.projectOwner}
                              </div>
                            </div>
                          </CardMedia>
                        )}
                      {project.projectMedia.length > 0 &&
                        !ReactPlayer.canPlay(project.projectMedia[0]) && (
                          <CardMedia
                            onClick={() => navigateToProjectPage(project.id)}
                            className="card-media"
                            sx={{
                              background: `linear-gradient(
                              0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%
                            ), url(https://d71lxjj3xpfs2.cloudfront.net/${project.projectMedia[0]}) lightgray 50% / cover no-repeat`,
                            }}
                            title="photoshoot"
                          >
                            <div>
                              <div className="title">
                                {project.projectName}{" "}
                              </div>
                              {project.projectRolesArray && (
                                <ProjectRoles
                                  roles={project.projectRolesArray}
                                />
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                marginLeft: "20px",
                                marginBottom: "10px",
                              }}
                            >
                              <Avatar
                                className="avatar"
                                {...stringAvatar(project.projectOwner)}
                              />
                              <div className="owner">
                                {project.projectOwner}
                              </div>
                            </div>
                          </CardMedia>
                        )}

                      {!project.projectMedia.length && (
                        <CardMedia
                          onClick={() => navigateToProjectPage(project.id)}
                          className="card-media"
                          sx={{
                            background: `${colors[getNextColorIndex()]}`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          title="photoshoot"
                        >
                          <div>
                            <div className="title">{project.projectName} </div>
                            {project.projectRolesArray && (
                              <ProjectRoles roles={project.projectRolesArray} />
                            )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginLeft: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(project.projectOwner)}
                            />
                            <div className="owner">{project.projectOwner}</div>
                          </div>
                        </CardMedia>
                      )}

                      <CardContent
                        sx={{ padding: "6px 12px 6px 12px", height: "50px" }}
                      >
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: project.projectDescription,
                          }}
                        ></div>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Chip
                          className="chip"
                          icon={
                            <GroupsIcon
                              sx={{ color: "#929292", width: "19px" }}
                            />
                          }
                          label={
                            project.projectRolesArray &&
                            `${project.projectRolesArray.length} people`
                          }
                        />
                        <Chip
                          className="chip"
                          icon={
                            <TimelapseIcon
                              sx={{ color: "#929292", width: "19px" }}
                            />
                          }
                          label={calculateDuration(
                            project.projectDurationQuantity,
                            project.projectDurationType
                          )}
                        />

                        <Button
                          size="small"
                          variant="outlined"
                          className="project-card-btn"
                          onClick={() => navigateToProjectPage(project.id)}
                        >
                          Learn More
                        </Button>
                      </CardActions>
                    </Card>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
