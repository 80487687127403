import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { useNavigate, useLocation } from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import NotesIcon from "@mui/icons-material/Notes";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const ViewSubmissions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [applications, setApplications] = useState(
    data ? data.applications : []
  );

  const handleClose = () => {
    setOpen(false);
    setComment("");
  };

  const handleOpen = (comment) => {
    setOpen(true);
    setComment(comment);
  };

  const navigateToProfile = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const handleBack = () => {
    navigate(`/submissions/${data.projectId}`);
  };

  const handleApproval = async (userEmail, userName, userId, applicationId) => {
    const body = {
      userEmail,
      roleName: data.name,
      userName,
      userId,
      roleId: data.id,
      id: data.projectId,
    };
    try {
      const response = await fetch(
        "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects/team",
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "PUT",
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Update the local applications state
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.id === applicationId ? { ...app, approved: true } : app
        )
      );
      const result = await response.json();
    } catch (error) {
      console.error("Approval failed:", error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {data ? (
        <Box
          className="custom-margins"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "3rem",
            paddingTop: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <IconButton
                sx={{ marginBottom: "10px" }}
                size="large"
                onClick={handleBack}
              >
                <NavigateBeforeIcon fontSize="large" />
              </IconButton>
              <Typography
                variant="h4"
                sx={{ textAlign: "left", fontWeight: "300" }}
              >
                {data.name}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "left", fontWeight: "400" }}
              >
                Submissions
              </Typography>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {applications &&
                  applications.map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        padding: "20px !important",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                        gap: "10px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        borderRadius: "10px",
                        height: "280px",
                        width: "260px",
                        flexDirection: "column",
                      }}
                    >
                      <Avatar
                        sx={{ width: "120px", height: "120px" }}
                        alt={item.userDetails.userName}
                        src={item.userDetails.profileImageUrl}
                      />{" "}
                      <Typography variant="h5" sx={{ fontWeight: "300" }}>
                        {item.userDetails.userName}
                      </Typography>
                      <Button
                        size="small"
                        sx={{
                          backgroundColor: "#564a92",
                          ":hover": {
                            bgcolor: "#564a92b8",
                          },
                          borderRadius: "10px",
                          color: "#ffffff",
                        }}
                        onClick={() => handleOpen(item.customNote)}
                        variant="outlined"
                        startIcon={<NotesIcon />}
                      >
                        Note
                      </Button>
                      <Typography variant="overline" sx={{ fontWeight: "400" }}>
                        Applied: {dayjs(item.createdAt).format("M/D/YY")}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "20px" }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: "#564a92",
                            ":hover": {
                              bgcolor: "#564a92b8",
                            },
                            borderRadius: "10px",
                          }}
                          onClick={() =>
                            navigateToProfile(item.userDetails.userId)
                          }
                        >
                          Profile
                        </Button>
                        {item.approved ? (
                          <Chip
                            label="Approved"
                            variant="outlined"
                            sx={{ color: "#564a92" }}
                          />
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              backgroundColor: "#564a92",
                              ":hover": {
                                bgcolor: "#564a92b8",
                              },
                              borderRadius: "10px",
                            }}
                            onClick={() =>
                              handleApproval(
                                item.userDetails.userEmail,
                                item.userDetails.userName,
                                item.userDetails.userId,
                                item.id
                              )
                            }
                          >
                            Approve
                          </Button>
                        )}
                      </Box>
                      <Dialog
                        onClose={handleClose}
                        open={open}
                        PaperProps={{
                          style: { width: "50%" }, // Custom width
                        }}
                      >
                        <DialogTitle>Custom Note</DialogTitle>
                        <DialogContent>
                          <DialogContentText>{comment}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Skeleton variant="rounded" width={300} height={70} />
          <Skeleton variant="rounded" width={600} height={40} />
          <Grid sx={{ marginBottom: "40px" }} container spacing={1}>
            <Grid
              sx={{
                width: "560px",
                height: "344px",
                position: "relative",
                overflow: "hidden",
              }}
              item
              xs={6}
            >
              <Skeleton variant="rounded" height={320} />
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Skeleton variant="rounded" width={1200} height={300} />
        </Stack>
      )}
    </Box>
  );
};

export default ViewSubmissions;
